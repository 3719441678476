/* eslint-disable */
import * as Types from '../../../../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CountDevicesVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.DeviceFilterDTO>;
}>;


export type CountDevicesDTO = (
  { __typename?: 'Query' }
  & Pick<Types.QueryDTO, 'devicesCount'>
);


export const CountDevicesDocumentDTO = gql`
    query countDevices($filter: DeviceFilter) {
  devicesCount(filter: $filter)
}
    `;

/**
 * __useCountDevices__
 *
 * To run a query within a React component, call `useCountDevices` and pass it any options that fit your needs.
 * When your component renders, `useCountDevices` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountDevices({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCountDevices(baseOptions?: Apollo.QueryHookOptions<CountDevicesDTO, CountDevicesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountDevicesDTO, CountDevicesVariablesDTO>(CountDevicesDocumentDTO, options);
      }
export function useCountDevicesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<CountDevicesDTO, CountDevicesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountDevicesDTO, CountDevicesVariablesDTO>(CountDevicesDocumentDTO, options);
        }
export type CountDevicesHookResult = ReturnType<typeof useCountDevices>;
export type CountDevicesLazyQueryDTOHookResult = ReturnType<typeof useCountDevicesLazyQueryDTO>;
export type CountDevicesQueryResultDTO = Apollo.QueryResult<CountDevicesDTO, CountDevicesVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    