/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  Time: any;
  Upload: any;
};


export type AcceptedPolicyInputDTO = {
  id: Scalars['String'];
  type: PolicyTypeDTO;
  accepted: Scalars['Boolean'];
};

export type AchievementDTO = {
  __typename?: 'Achievement';
  id: Scalars['String'];
  badgeImage: Scalars['String'];
  description: Scalars['String'];
  goal: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
  recurring: Scalars['Boolean'];
  coinsToEarn: Scalars['Int'];
  unlocked: Scalars['Boolean'];
  progress: Scalars['Float'];
  achievementProgresses: Array<AchievementProgressDTO>;
  achievementsUnlocked: Array<AchievementUnlockedDTO>;
};

export type AchievementCreateInputDTO = {
  badgeImage: Scalars['String'];
  description: Scalars['String'];
  goal: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
  visible: Scalars['Boolean'];
  recurring: Scalars['Boolean'];
  coinsToEarn: Scalars['Int'];
};

export type AchievementFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  visible?: Maybe<BooleanFilterDTO>;
  type?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<AchievementFilterDTO>>;
  OR?: Maybe<Array<AchievementFilterDTO>>;
};

export type AchievementProgressDTO = {
  __typename?: 'AchievementProgress';
  id: Scalars['String'];
  progress: Scalars['Int'];
  pupil: PupilDTO;
  achievement: AchievementDTO;
};

export type AchievementUnlockedDTO = {
  __typename?: 'AchievementUnlocked';
  id: Scalars['String'];
  pupil: PupilDTO;
  unlockedOn: Scalars['DateTime'];
  achievement: AchievementDTO;
};

export type AchievementUpdateInputDTO = {
  badgeImage?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
  recurring?: Maybe<Scalars['Boolean']>;
  coinsToEarn?: Maybe<Scalars['Int']>;
};

export type AirQualityDTO = {
  __typename?: 'AirQuality';
  id: Scalars['String'];
  airQuality: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  location: LocationDTO;
};

export type AirQualityFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  airQuality?: Maybe<IntFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  location?: Maybe<BasicStringFilterDTO>;
  AND?: Maybe<Array<AirQualityFilterDTO>>;
  OR?: Maybe<Array<AirQualityFilterDTO>>;
};

export type AnonymousTokenInputDTO = {
  schoolCode: Scalars['String'];
};

export type AssetDTO = {
  __typename?: 'Asset';
  id: Scalars['String'];
  url: Scalars['String'];
  fileName: Scalars['String'];
  fileSizeBytes: Scalars['Int'];
};

export type AssetUploadInputDTO = {
  contentBase64: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
};

export type AvatarDTO = {
  __typename?: 'Avatar';
  id: Scalars['String'];
  eyebrows: Scalars['String'];
  face: Scalars['String'];
  hair: Scalars['String'];
  hairColor: Scalars['String'];
  head: Scalars['String'];
  nose: Scalars['String'];
  skinColor: Scalars['String'];
  sweater: Scalars['String'];
};

export type AvatarCreateInputDTO = {
  eyebrows: Scalars['String'];
  face: Scalars['String'];
  hair: Scalars['String'];
  hairColor: Scalars['String'];
  head: Scalars['String'];
  nose: Scalars['String'];
  skinColor: Scalars['String'];
  sweater: Scalars['String'];
};

export type BasicStringArrayFilterDTO = {
  containsSome?: Maybe<Array<Maybe<Scalars['String']>>>;
  containsAll?: Maybe<Array<Maybe<Scalars['String']>>>;
  equals?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BasicStringFilterDTO = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type BooleanFilterDTO = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<Scalars['Boolean']>;
};

export type CampaignDTO = {
  __typename?: 'Campaign';
  id: Scalars['String'];
  title: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  coinsToEarn: Scalars['Int'];
  coinEarnModel: CampaignCoinEarnModelDTO;
  classes?: Maybe<Array<ClassDTO>>;
  checkSummary?: Maybe<Array<Maybe<CampaignCheckSummaryDTO>>>;
  checks?: Maybe<Array<Maybe<CampaignCheckDTO>>>;
};


export type CampaignCheckSummaryArgsDTO = {
  filter?: Maybe<CampaignCheckFilterDTO>;
  groupBy: CampaignCheckSummaryGroupByDTO;
};


export type CampaignChecksArgsDTO = {
  filter?: Maybe<CampaignCheckFilterDTO>;
};

export type CampaignCheckDTO = {
  __typename?: 'CampaignCheck';
  pupil: PupilDTO;
  date: Scalars['DateTime'];
};

export type CampaignCheckCreateManyInputDTO = {
  campaign: Scalars['String'];
  pupils: Array<Scalars['String']>;
  date: Scalars['DateTime'];
};

export type CampaignCheckFilterDTO = {
  date?: Maybe<DateTimeFilterDTO>;
  pupil?: Maybe<PupilReferenceFilterDTO>;
};

export type CampaignCheckSummaryDTO = {
  __typename?: 'CampaignCheckSummary';
  pupil?: Maybe<PupilDTO>;
  class?: Maybe<ClassDTO>;
  school?: Maybe<SchoolDTO>;
  schoolyear?: Maybe<SchoolyearDTO>;
  project?: Maybe<ProjectDTO>;
  count: Scalars['Int'];
};

export enum CampaignCheckSummaryGroupByDTO {
  PupilDTO = 'PUPIL',
  ClassDTO = 'CLASS',
  SchoolDTO = 'SCHOOL',
  SchoolyearDTO = 'SCHOOLYEAR',
  ProjectDTO = 'PROJECT'
}

export enum CampaignCoinEarnModelDTO {
  OnceDTO = 'ONCE',
  EveryDayDTO = 'EVERY_DAY'
}

export type CampaignCreateInputDTO = {
  title: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  coinsToEarn: Scalars['Int'];
  coinEarnModel: CampaignCoinEarnModelDTO;
  classes: Array<Scalars['String']>;
};

export type CampaignFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  activeFrom?: Maybe<DateTimeFilterDTO>;
  activeTo?: Maybe<DateTimeFilterDTO>;
  class?: Maybe<BasicStringArrayFilterDTO>;
  school?: Maybe<BasicStringArrayFilterDTO>;
  schoolyear?: Maybe<BasicStringArrayFilterDTO>;
  project?: Maybe<BasicStringArrayFilterDTO>;
  AND?: Maybe<Array<CampaignFilterDTO>>;
  OR?: Maybe<Array<CampaignFilterDTO>>;
};

export type CampaignUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  coinsToEarn?: Maybe<Scalars['Int']>;
  coinEarnModel?: Maybe<CampaignCoinEarnModelDTO>;
  classes?: Maybe<Array<Scalars['String']>>;
};

export type ChallengeDTO = {
  __typename?: 'Challenge';
  id: Scalars['String'];
  title: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  school: SchoolDTO;
  classes: Array<ClassDTO>;
  scoringType: Scalars['String'];
  description: Scalars['String'];
  goal: Scalars['Int'];
  progress: Array<ChallengeProgressDTO>;
};

export type ChallengeCreateInputDTO = {
  title: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  school: Scalars['String'];
  classes?: Maybe<Array<Scalars['String']>>;
  scoringType: Scalars['String'];
  description: Scalars['String'];
  goal: Scalars['Int'];
};

export type ChallengeFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  description?: Maybe<StringFilterDTO>;
  activeFrom?: Maybe<DateTimeFilterDTO>;
  activeTo?: Maybe<DateTimeFilterDTO>;
  school?: Maybe<SchoolFilterDTO>;
  class?: Maybe<BasicStringArrayFilterDTO>;
  AND?: Maybe<Array<ChallengeFilterDTO>>;
  OR?: Maybe<Array<ChallengeFilterDTO>>;
};

export type ChallengeProgressDTO = {
  __typename?: 'ChallengeProgress';
  classId?: Maybe<Scalars['String']>;
  progress: Scalars['Int'];
};

export type ChallengeSearchItemDTO = SearchItemDTO & {
  __typename?: 'ChallengeSearchItem';
  type: SearchTypeDTO;
  challenge: ChallengeDTO;
};

export type ChallengeUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  school?: Maybe<Scalars['String']>;
  classes?: Maybe<Array<Scalars['String']>>;
  scoringType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goal?: Maybe<Scalars['Int']>;
};

export type ClassDTO = {
  __typename?: 'Class';
  id: Scalars['String'];
  title: Scalars['String'];
  pupils?: Maybe<Array<PupilDTO>>;
  pupilsCount: Scalars['Int'];
  school: SchoolDTO;
  classTotal: ClassTotalDTO;
  moduleRegistrationsCount: Scalars['Int'];
  moduleRegistrationStatistics: ModuleRegistrationStatisticsDTO;
};


export type ClassModuleRegistrationsCountArgsDTO = {
  filter?: Maybe<ModuleRegistrationReferenceFilterDTO>;
};


export type ClassModuleRegistrationStatisticsArgsDTO = {
  filter?: Maybe<ModuleRegistrationReferenceFilterDTO>;
};

export type ClassCreateInputDTO = {
  title: Scalars['String'];
  school: Scalars['String'];
};

export type ClassExerciseDTO = {
  __typename?: 'ClassExercise';
  id: Scalars['String'];
  class: ClassDTO;
  exercise: ExerciseDTO;
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link?: Maybe<ClassExerciseLinkDTO>;
};

export type ClassExerciseCreateInputDTO = {
  class: Scalars['String'];
  exercise: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link?: Maybe<ClassExerciseLinkCreateInputDTO>;
};

export type ClassExerciseCreateManyExerciseInputDTO = {
  id: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
};

export type ClassExerciseCreateManyInputDTO = {
  classIds: Array<Scalars['String']>;
  exercises: Array<ClassExerciseCreateManyExerciseInputDTO>;
};

export type ClassExerciseFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  class?: Maybe<BasicStringFilterDTO>;
  exercise?: Maybe<ExerciseFilterDTO>;
  link?: Maybe<ClassExerciseLinkFilterDTO>;
  activeFrom?: Maybe<DateTimeFilterDTO>;
  activeTo?: Maybe<DateTimeFilterDTO>;
  AND?: Maybe<Array<ClassExerciseFilterDTO>>;
  OR?: Maybe<Array<ClassExerciseFilterDTO>>;
};

export type ClassExerciseForExerciseCreateInputDTO = {
  id?: Maybe<Scalars['String']>;
  class: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link?: Maybe<ClassExerciseLinkCreateInputDTO>;
};

export type ClassExerciseLinkDTO = {
  __typename?: 'ClassExerciseLink';
  id: Scalars['String'];
  classLibrary: ClassLibraryDTO;
  linkedBy?: Maybe<Scalars['String']>;
  linkedByName?: Maybe<Scalars['String']>;
};

export type ClassExerciseLinkCreateInputDTO = {
  classLibrary: Scalars['String'];
  linkedBy?: Maybe<Scalars['String']>;
  linkedByName?: Maybe<Scalars['String']>;
};

export type ClassExerciseLinkFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  classLibrary?: Maybe<BasicStringFilterDTO>;
  class?: Maybe<ClassReferenceFilterDTO>;
  AND?: Maybe<Array<ClassExerciseLinkFilterDTO>>;
  OR?: Maybe<Array<ClassExerciseLinkFilterDTO>>;
};

export type ClassExerciseLinkReferenceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  classLibrary?: Maybe<BasicStringFilterDTO>;
  class?: Maybe<ClassReferenceFilterDTO>;
  exists?: Maybe<Scalars['Boolean']>;
  AND?: Maybe<Array<ClassExerciseLinkReferenceFilterDTO>>;
  OR?: Maybe<Array<ClassExerciseLinkReferenceFilterDTO>>;
};

export type ClassExerciseUpdateInputDTO = {
  class?: Maybe<Scalars['String']>;
  exercise?: Maybe<Scalars['String']>;
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  link?: Maybe<ClassExerciseLinkCreateInputDTO>;
};

export type ClassExerciseUpdateManyExerciseInputDTO = {
  id: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
};

export type ClassFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  school?: Maybe<SchoolFilterDTO>;
  AND?: Maybe<Array<ClassFilterDTO>>;
  OR?: Maybe<Array<ClassFilterDTO>>;
};

export type ClassLibraryDTO = {
  __typename?: 'ClassLibrary';
  id: Scalars['String'];
  class: ClassDTO;
  exercise: ExerciseDTO;
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link?: Maybe<ClassExerciseLinkDTO>;
};

export type ClassLibraryCreateInputDTO = {
  class: Scalars['String'];
  exercise: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
  link: Scalars['String'];
};

export type ClassLibraryCreateManyExerciseInputDTO = {
  id: Scalars['String'];
  activeFrom: Scalars['DateTime'];
  activeTo: Scalars['DateTime'];
};

export type ClassLibraryCreateManyInputDTO = {
  classIds: Array<Scalars['String']>;
  exercises: Array<ClassLibraryCreateManyExerciseInputDTO>;
};

export type ClassLibraryFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  class?: Maybe<ClassFilterDTO>;
  exercise?: Maybe<ExerciseFilterDTO>;
  link?: Maybe<ClassExerciseLinkFilterDTO>;
  activeFrom?: Maybe<DateTimeFilterDTO>;
  activeTo?: Maybe<DateTimeFilterDTO>;
  AND?: Maybe<Array<ClassLibraryFilterDTO>>;
  OR?: Maybe<Array<ClassLibraryFilterDTO>>;
};

export type ClassLibraryUpdateInputDTO = {
  class?: Maybe<Scalars['String']>;
  exercise?: Maybe<Scalars['String']>;
  activeFrom?: Maybe<Scalars['DateTime']>;
  activeTo?: Maybe<Scalars['DateTime']>;
  link?: Maybe<Scalars['String']>;
};

export type ClassMessageDTO = {
  __typename?: 'ClassMessage';
  id: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  showFrom: Scalars['DateTime'];
  showTo: Scalars['DateTime'];
};

export type ClassReferenceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  school?: Maybe<SchoolReferenceFilterDTO>;
  AND?: Maybe<Array<ClassReferenceFilterDTO>>;
  OR?: Maybe<Array<ClassReferenceFilterDTO>>;
};

export type ClassSearchItemDTO = SearchItemDTO & {
  __typename?: 'ClassSearchItem';
  type: SearchTypeDTO;
  class: ClassDTO;
};

export type ClassTotalDTO = {
  __typename?: 'ClassTotal';
  id: Scalars['String'];
  class: ClassDTO;
  totalCredits: Scalars['Int'];
  totalRegistrations: Scalars['Int'];
  totalDistance: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  highestTotalCreditsOnOneDay: Scalars['Int'];
};

export type ClassUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
};

export type ContentBlockDTO = {
  __typename?: 'ContentBlock';
  id: Scalars['String'];
  title: Scalars['String'];
  published: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  showAsMenuItem: Scalars['Boolean'];
  menuTitle?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  content: Scalars['String'];
  visibility: ContentBlockVisibilityDTO;
  order?: Maybe<Scalars['Int']>;
  schoolyear: SchoolyearDTO;
  schools: Array<SchoolDTO>;
};

export type ContentBlockCreateInputDTO = {
  title: Scalars['String'];
  published: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  showAsMenuItem: Scalars['Boolean'];
  menuTitle?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  content: Scalars['String'];
  visibility: ContentBlockVisibilityDTO;
  order?: Maybe<Scalars['Int']>;
  schoolyear: Scalars['String'];
  schools?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContentBlockFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  published?: Maybe<BooleanFilterDTO>;
  url?: Maybe<StringFilterDTO>;
  showAsMenuItem?: Maybe<BooleanFilterDTO>;
  type?: Maybe<StringFilterDTO>;
  visibility?: Maybe<ContentBlockVisibilityFilterDTO>;
  schoolyear?: Maybe<BasicStringFilterDTO>;
  AND?: Maybe<Array<ContentBlockFilterDTO>>;
  OR?: Maybe<Array<ContentBlockFilterDTO>>;
};

export type ContentBlockUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  showAsMenuItem?: Maybe<Scalars['Boolean']>;
  menuTitle?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  visibility?: Maybe<ContentBlockVisibilityDTO>;
  order?: Maybe<Scalars['Int']>;
  schools?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ContentBlockVisibilityDTO {
  PrivateDTO = 'PRIVATE',
  PublicDTO = 'PUBLIC'
}

export type ContentBlockVisibilityFilterDTO = {
  equals?: Maybe<ContentBlockVisibilityDTO>;
  not?: Maybe<ContentBlockVisibilityDTO>;
  in?: Maybe<Array<Maybe<ContentBlockVisibilityDTO>>>;
  notIn?: Maybe<Array<Maybe<ContentBlockVisibilityDTO>>>;
};



export type DateTimeFilterDTO = {
  equals?: Maybe<Scalars['DateTime']>;
  not?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  isNull?: Maybe<Scalars['Boolean']>;
};

export type DeviceDTO = {
  __typename?: 'Device';
  id: Scalars['String'];
  type: DeviceTypeDTO;
};

export type DeviceCreateInputDTO = {
  id: Scalars['String'];
  type: DeviceTypeDTO;
};

export type DeviceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  type?: Maybe<DeviceTypeFilterDTO>;
  AND?: Maybe<Array<DeviceFilterDTO>>;
  OR?: Maybe<Array<DeviceFilterDTO>>;
};

export enum DeviceTypeDTO {
  HttpDTO = 'http',
  UdpDTO = 'udp',
  IotcDTO = 'iotc'
}

export type DeviceTypeFilterDTO = {
  equals?: Maybe<DeviceTypeDTO>;
  not?: Maybe<DeviceTypeDTO>;
  in?: Maybe<Array<Maybe<DeviceTypeDTO>>>;
  notIn?: Maybe<Array<Maybe<DeviceTypeDTO>>>;
};

export type ExerciseDTO = {
  __typename?: 'Exercise';
  id: Scalars['String'];
  title: Scalars['String'];
  tags: Array<ExerciseTagDTO>;
  course: ExerciseCourseDTO;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  creditsToEarn: Scalars['Int'];
  grade?: Maybe<ExerciseGradeDTO>;
  image: AssetDTO;
  library?: Maybe<Scalars['Boolean']>;
  owner: Scalars['String'];
  ownerName: Scalars['String'];
  questions: Array<ExerciseQuestionDTO>;
  results: Array<PupilExerciseResultDTO>;
  classExercises: Array<ClassExerciseDTO>;
  classExercisesCount: Scalars['Int'];
};

export type ExerciseAnswerDTO = {
  __typename?: 'ExerciseAnswer';
  id: Scalars['String'];
  question: ExerciseQuestionDTO;
  answer: Scalars['String'];
  image?: Maybe<AssetDTO>;
  correct: Scalars['Boolean'];
};

export type ExerciseAnswerCreateInputDTO = {
  question: Scalars['String'];
  answer: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  correct: Scalars['Boolean'];
};

export type ExerciseAnswerForQuestionCreateInputDTO = {
  id?: Maybe<Scalars['String']>;
  answer: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  correct: Scalars['Boolean'];
};

export type ExerciseAnswerUpdateInputDTO = {
  answer?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  correct?: Maybe<Scalars['Boolean']>;
};

export type ExerciseCourseDTO = {
  __typename?: 'ExerciseCourse';
  id: Scalars['String'];
  title: Scalars['String'];
  image: AssetDTO;
  exercises?: Maybe<Array<Maybe<ExerciseDTO>>>;
  exercisesCount: Scalars['Int'];
  classExercisesCount: Scalars['Int'];
};

export type ExerciseCourseCreateInputDTO = {
  title: Scalars['String'];
  image: Scalars['String'];
};

export type ExerciseCourseFilterDTO = {
  id?: Maybe<StringFilterDTO>;
};

export type ExerciseCourseUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type ExerciseCreateInputDTO = {
  title: Scalars['String'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  course: Scalars['String'];
  creditsToEarn: Scalars['Int'];
  grade?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  library?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<ExerciseQuestionForExerciseCreateInputDTO>>;
  classExercises?: Maybe<Array<ClassExerciseForExerciseCreateInputDTO>>;
};

export type ExerciseFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  owner?: Maybe<StringFilterDTO>;
  course?: Maybe<BasicStringFilterDTO>;
  grade?: Maybe<BasicStringFilterDTO>;
  tags?: Maybe<BasicStringFilterDTO>;
  library?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<ExerciseFilterDTO>>;
  OR?: Maybe<Array<ExerciseFilterDTO>>;
};

export type ExerciseGradeDTO = {
  __typename?: 'ExerciseGrade';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type ExerciseGradeCreateInputDTO = {
  title: Scalars['String'];
};

export type ExerciseGradeFilterDTO = {
  id?: Maybe<StringFilterDTO>;
};

export type ExerciseGradeUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
};

export type ExerciseQuestionDTO = {
  __typename?: 'ExerciseQuestion';
  id: Scalars['String'];
  answers: Array<ExerciseAnswerDTO>;
  type: ExerciseQuestionTypeDTO;
  question: Scalars['String'];
  image?: Maybe<AssetDTO>;
  order: Scalars['Int'];
};

export type ExerciseQuestionCreateInputDTO = {
  exercise: Scalars['String'];
  type: ExerciseQuestionTypeDTO;
  question: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  answers?: Maybe<Array<ExerciseAnswerForQuestionCreateInputDTO>>;
};

export type ExerciseQuestionFilterDTO = {
  id?: Maybe<StringFilterDTO>;
};

export type ExerciseQuestionForExerciseCreateInputDTO = {
  id?: Maybe<Scalars['String']>;
  type: ExerciseQuestionTypeDTO;
  question: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
  answers?: Maybe<Array<ExerciseAnswerForQuestionCreateInputDTO>>;
};

export enum ExerciseQuestionTypeDTO {
  ImageDTO = 'IMAGE',
  TextDTO = 'TEXT'
}

export type ExerciseQuestionUpdateInputDTO = {
  type?: Maybe<ExerciseQuestionTypeDTO>;
  question?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  answers?: Maybe<Array<ExerciseAnswerForQuestionCreateInputDTO>>;
};

export type ExerciseReferenceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  owner?: Maybe<StringFilterDTO>;
  course?: Maybe<BasicStringFilterDTO>;
  grade?: Maybe<BasicStringFilterDTO>;
  tags?: Maybe<BasicStringFilterDTO>;
  library?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<ExerciseReferenceFilterDTO>>;
  OR?: Maybe<Array<ExerciseReferenceFilterDTO>>;
};

export type ExerciseSearchItemDTO = SearchItemDTO & {
  __typename?: 'ExerciseSearchItem';
  type: SearchTypeDTO;
  exercise: ExerciseDTO;
};

export type ExerciseTagDTO = {
  __typename?: 'ExerciseTag';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type ExerciseTagCreateInputDTO = {
  title: Scalars['String'];
};

export type ExerciseTagFilterDTO = {
  id?: Maybe<StringFilterDTO>;
};

export type ExerciseTagUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
};

export type ExerciseUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  course?: Maybe<Scalars['String']>;
  creditsToEarn?: Maybe<Scalars['Int']>;
  grade?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  library?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<ExerciseQuestionForExerciseCreateInputDTO>>;
  classExercises?: Maybe<Array<ClassExerciseForExerciseCreateInputDTO>>;
};

export type GlobalRegimeInactivityInputDTO = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type HazardousLocationDTO = {
  __typename?: 'HazardousLocation';
  id: Scalars['String'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  reason: HazardousLocationReasonDTO;
  location: LocationDTO;
  reportedBy: Scalars['String'];
  status?: Maybe<HazardousLocationStatusDTO>;
  declinedAt?: Maybe<Scalars['DateTime']>;
  declinedBy?: Maybe<Scalars['String']>;
  declinedReason?: Maybe<Scalars['String']>;
};

export type HazardousLocationCreateInputDTO = {
  reason: Scalars['String'];
  location: LocationCreateInputDTO;
  reportedBy: Scalars['String'];
  schoolyear: Scalars['String'];
};

export type HazardousLocationFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  schoolyear?: Maybe<BasicStringFilterDTO>;
  status?: Maybe<HazardousLocationStatusFilterDTO>;
  AND?: Maybe<Array<HazardousLocationFilterDTO>>;
  OR?: Maybe<Array<HazardousLocationFilterDTO>>;
};

export type HazardousLocationReasonDTO = {
  __typename?: 'HazardousLocationReason';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type HazardousLocationReasonCreateInputDTO = {
  title: Scalars['String'];
  project: Scalars['String'];
};

export type HazardousLocationReasonFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<HazardousLocationReasonFilterDTO>>;
  OR?: Maybe<Array<HazardousLocationReasonFilterDTO>>;
};

export type HazardousLocationReasonUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
};

export enum HazardousLocationStatusDTO {
  ApprovedDTO = 'APPROVED',
  DeclinedDTO = 'DECLINED',
  ReportedDTO = 'REPORTED'
}

export type HazardousLocationStatusFilterDTO = {
  equals?: Maybe<HazardousLocationStatusDTO>;
  not?: Maybe<HazardousLocationStatusDTO>;
  in?: Maybe<Array<Maybe<HazardousLocationStatusDTO>>>;
  notIn?: Maybe<Array<Maybe<HazardousLocationStatusDTO>>>;
};

export type HazardousLocationUpdateInputDTO = {
  status?: Maybe<HazardousLocationStatusDTO>;
  declinedReason?: Maybe<Scalars['String']>;
};

export type IntFilterDTO = {
  equals?: Maybe<Scalars['Int']>;
  not?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
};


export type LocationDTO = {
  __typename?: 'Location';
  id: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  friendlyName?: Maybe<Scalars['String']>;
  schoolyear?: Maybe<SchoolyearDTO>;
  airQualities: Array<AirQualityDTO>;
  airQualitiesCount: Scalars['Int'];
};


export type LocationAirQualitiesArgsDTO = {
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};

export type LocationCreateInputDTO = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  friendlyName?: Maybe<Scalars['String']>;
};

export type LocationFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  schoolyear?: Maybe<BasicStringFilterDTO>;
  AND?: Maybe<Array<LocationFilterDTO>>;
  OR?: Maybe<Array<LocationFilterDTO>>;
};

export type LoginResultDTO = {
  __typename?: 'LoginResult';
  token: Scalars['String'];
};

export type ModuleDTO = {
  __typename?: 'Module';
  id: Scalars['String'];
  deviceId: Scalars['String'];
  location: LocationDTO;
  title: Scalars['String'];
  ignoreOnRoute: Scalars['Boolean'];
  softDeleted: Scalars['Boolean'];
  moduleRegistrations?: Maybe<Array<Maybe<ModuleRegistrationDTO>>>;
  moduleRegistrationsCount: Scalars['Int'];
  regime: RegimeDTO;
  moduleTypes?: Maybe<Array<Maybe<ModuleTypeDTO>>>;
  reference?: Maybe<Scalars['String']>;
};


export type ModuleModuleRegistrationsArgsDTO = {
  filter?: Maybe<ModuleRegistrationReferenceFilterDTO>;
};


export type ModuleModuleRegistrationsCountArgsDTO = {
  filter?: Maybe<ModuleRegistrationReferenceFilterDTO>;
};

export type ModuleCreateInputDTO = {
  deviceId: Scalars['String'];
  location: LocationCreateInputDTO;
  title: Scalars['String'];
  ignoreOnRoute: Scalars['Boolean'];
  regime: Scalars['String'];
  moduleTypes: Array<ModuleTypeCreateInputDTO>;
  schoolyear: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
};

export type ModuleFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  location?: Maybe<BasicStringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  type?: Maybe<StringArrayFilterDTO>;
  softDeleted?: Maybe<BooleanFilterDTO>;
  schoolyear?: Maybe<BasicStringFilterDTO>;
  AND?: Maybe<Array<ModuleFilterDTO>>;
  OR?: Maybe<Array<ModuleFilterDTO>>;
};

export type ModuleReferenceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  location?: Maybe<BasicStringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  softDeleted?: Maybe<BooleanFilterDTO>;
  schoolyear?: Maybe<BasicStringFilterDTO>;
  AND?: Maybe<Array<ModuleReferenceFilterDTO>>;
  OR?: Maybe<Array<ModuleReferenceFilterDTO>>;
};

export type ModuleRegistrationDTO = {
  __typename?: 'ModuleRegistration';
  id: Scalars['String'];
  pupil: PupilDTO;
  createdAt: Scalars['DateTime'];
  identifier: Scalars['String'];
  module: ModuleDTO;
  transaction: PupilCreditDTO;
  replaced: Scalars['Boolean'];
  coinsEarned: Scalars['Int'];
  distance: Scalars['Float'];
  type: Scalars['String'];
};

export type ModuleRegistrationFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  pupil?: Maybe<PupilFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  module?: Maybe<ModuleFilterDTO>;
  replaced?: Maybe<BooleanFilterDTO>;
  type?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<ModuleRegistrationFilterDTO>>;
  OR?: Maybe<Array<ModuleRegistrationFilterDTO>>;
};

export type ModuleRegistrationReferenceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  pupil?: Maybe<PupilReferenceFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  module?: Maybe<ModuleReferenceFilterDTO>;
  replaced?: Maybe<BooleanFilterDTO>;
  type?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<ModuleRegistrationReferenceFilterDTO>>;
  OR?: Maybe<Array<ModuleRegistrationReferenceFilterDTO>>;
};

export type ModuleRegistrationSimulateInputDTO = {
  pupil: Scalars['String'];
  module: Scalars['String'];
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type ModuleRegistrationStatisticsDTO = {
  __typename?: 'ModuleRegistrationStatistics';
  totalDistance: Scalars['Float'];
};

export enum ModuleRegistrationTimeSeriesGroupByDTO {
  ClassDTO = 'CLASS',
  ModuleDTO = 'MODULE',
  PupilDTO = 'PUPIL',
  SchoolDTO = 'SCHOOL'
}

export type ModuleTypeDTO = {
  __typename?: 'ModuleType';
  type: Scalars['String'];
  coinsToEarn: Scalars['Int'];
};

export type ModuleTypeCreateInputDTO = {
  type: Scalars['String'];
  coinsToEarn: Scalars['Int'];
};

export type ModuleUpdateInputDTO = {
  regime?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  location?: Maybe<LocationCreateInputDTO>;
  moduleTypes?: Maybe<Array<ModuleTypeCreateInputDTO>>;
  reference?: Maybe<Scalars['String']>;
};

export type MultiplierDTO = {
  __typename?: 'Multiplier';
  id: Scalars['String'];
  school: SchoolDTO;
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  multiplier: Scalars['Int'];
};

export type MultiplierCreateInputDTO = {
  school: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  multiplier: Scalars['Int'];
};

export type MultiplierFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  school?: Maybe<BasicStringFilterDTO>;
  startsAt?: Maybe<DateTimeFilterDTO>;
  endsAt?: Maybe<DateTimeFilterDTO>;
  multiplier?: Maybe<IntFilterDTO>;
  AND?: Maybe<Array<MultiplierFilterDTO>>;
  OR?: Maybe<Array<MultiplierFilterDTO>>;
};

export type MultiplierUpdateInputDTO = {
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  multiplier?: Maybe<Scalars['Int']>;
};

export type MutationDTO = {
  __typename?: 'Mutation';
  createOneAchievement: AchievementDTO;
  updateOneAchievement: AchievementDTO;
  deleteOneAchievement: SuccessDTO;
  uploadAsset: AssetDTO;
  createOneCampaign: CampaignDTO;
  updateOneCampaign: CampaignDTO;
  deleteOneCampaign: SuccessDTO;
  createManyCampaignChecks: SuccessDTO;
  createOneChallenge: ChallengeDTO;
  updateOneChallenge: ChallengeDTO;
  deleteOneChallenge: SuccessDTO;
  createOneClass: ClassDTO;
  updateOneClass: ClassDTO;
  deleteOneClass: SuccessDTO;
  createOneClassExercise: ClassExerciseDTO;
  updateOneClassExercise: ClassExerciseDTO;
  deleteOneClassExercise: SuccessDTO;
  createManyClassExercises: SuccessDTO;
  deleteClassExercises: SuccessDTO;
  deleteClassExerciseLinks: SuccessDTO;
  createOneClassLibrary: ClassLibraryDTO;
  updateOneClassLibrary: ClassLibraryDTO;
  deleteOneClassLibrary: SuccessDTO;
  createManyClassLibraries: SuccessDTO;
  deleteClassLibraries: SuccessDTO;
  createOneContentBlock: ContentBlockDTO;
  updateOneContentBlock: ContentBlockDTO;
  deleteOneContentBlock: SuccessDTO;
  createOneDevice: DeviceDTO;
  deleteOneDevice: SuccessDTO;
  createOneExercise: ExerciseDTO;
  updateOneExercise: ExerciseDTO;
  deleteOneExercise: SuccessDTO;
  completeExercise: SubmitResultDTO;
  createOneExerciseAnswer: ExerciseAnswerDTO;
  updateOneExerciseAnswer: ExerciseAnswerDTO;
  deleteOneExerciseAnswer: SuccessDTO;
  createOneExerciseCourse: ExerciseCourseDTO;
  updateOneExerciseCourse: ExerciseCourseDTO;
  deleteOneExerciseCourse: SuccessDTO;
  createOneExerciseGrade: ExerciseGradeDTO;
  updateOneExerciseGrade: ExerciseGradeDTO;
  deleteOneExerciseGrade: SuccessDTO;
  createOneExerciseQuestion: ExerciseQuestionDTO;
  updateOneExerciseQuestion: ExerciseQuestionDTO;
  deleteOneExerciseQuestion: SuccessDTO;
  createOneExerciseTag: ExerciseTagDTO;
  updateOneExerciseTag: ExerciseTagDTO;
  deleteOneExerciseTag: SuccessDTO;
  createOneHazardousLocation: HazardousLocationDTO;
  updateOneHazardousLocation: HazardousLocationDTO;
  deleteOneHazardousLocation: SuccessDTO;
  createOneHazardousLocationReason: HazardousLocationReasonDTO;
  updateOneHazardousLocationReason: HazardousLocationReasonDTO;
  deleteOneHazardousLocationReason: SuccessDTO;
  pupilLogin: LoginResultDTO;
  usernamePupilLogin: LoginResultDTO;
  nfcPupilLogin: LoginResultDTO;
  login: LoginResultDTO;
  createOneModule: ModuleDTO;
  updateOneModule: ModuleDTO;
  simulateOneModuleRegistration: SuccessDTO;
  createOneMultiplier: MultiplierDTO;
  updateOneMultiplier: MultiplierDTO;
  deleteOneMultiplier: SuccessDTO;
  resetPassword: SuccessDTO;
  createOneProject: ProjectDTO;
  updateOneProject: ProjectDTO;
  deleteOneProject: SuccessDTO;
  createOnePupil: PupilDTO;
  updateOnePupil: PupilDTO;
  deleteOnePupil: SuccessDTO;
  saveZoneForPupil: PupilDTO;
  validatePupilClassNumber: SuccessDTO;
  createOnePupilCredit: PupilCreditDTO;
  updateOnePupilCredit: PupilCreditDTO;
  deleteOnePupilCredit: SuccessDTO;
  updatePupilPassword: SuccessDTO;
  updatePupilPasswordAnonymous: SuccessDTO;
  pupilPasswordReset: SuccessDTO;
  validatePupilTag: SuccessDTO;
  createOneRegime: RegimeDTO;
  updateOneRegime: RegimeDTO;
  deleteOneRegime: SuccessDTO;
  deleteOneRegimeInactivity: SuccessDTO;
  createOneReward: RewardDTO;
  updateOneReward: RewardDTO;
  deleteOneReward: SuccessDTO;
  createOneRewardQuantity: RewardQuantityDTO;
  updateOneRewardQuantity: RewardQuantityDTO;
  deleteOneRewardQuantity: SuccessDTO;
  createOneRewardRequest: RewardRequestDTO;
  markOneRewardRequestAsReceived: RewardRequestDTO;
  createOneSchool: SchoolDTO;
  updateOneSchool: SchoolDTO;
  deleteOneSchool: SuccessDTO;
  createOneSchoolTrophy: SchoolTrophyDTO;
  updateOneSchoolTrophy: SchoolTrophyDTO;
  deleteOneSchoolTrophy: SuccessDTO;
  createOneSchoolyear: SchoolyearDTO;
  updateOneSchoolyear: SchoolyearDTO;
  deleteOneSchoolyear: SuccessDTO;
  getOneAnonymousToken: TokenResultDTO;
  createOneUser: UserDTO;
  updateOneUser: UserDTO;
  deleteOneUser: SuccessDTO;
  updatePassword: SuccessDTO;
  updatePasswordAnonymous: SuccessDTO;
  generateOneUsername: UsernameDTO;
  createOneZone: ZoneDTO;
  updateOneZone: ZoneDTO;
  deleteOneZone: SuccessDTO;
};


export type MutationCreateOneAchievementArgsDTO = {
  data: AchievementCreateInputDTO;
};


export type MutationUpdateOneAchievementArgsDTO = {
  id: Scalars['String'];
  data: AchievementUpdateInputDTO;
};


export type MutationDeleteOneAchievementArgsDTO = {
  id: Scalars['String'];
};


export type MutationUploadAssetArgsDTO = {
  data: AssetUploadInputDTO;
};


export type MutationCreateOneCampaignArgsDTO = {
  data: CampaignCreateInputDTO;
};


export type MutationUpdateOneCampaignArgsDTO = {
  id: Scalars['String'];
  data: CampaignUpdateInputDTO;
};


export type MutationDeleteOneCampaignArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateManyCampaignChecksArgsDTO = {
  data: CampaignCheckCreateManyInputDTO;
};


export type MutationCreateOneChallengeArgsDTO = {
  data: ChallengeCreateInputDTO;
};


export type MutationUpdateOneChallengeArgsDTO = {
  id: Scalars['String'];
  data: ChallengeUpdateInputDTO;
};


export type MutationDeleteOneChallengeArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneClassArgsDTO = {
  data: ClassCreateInputDTO;
};


export type MutationUpdateOneClassArgsDTO = {
  id: Scalars['String'];
  data: ClassUpdateInputDTO;
};


export type MutationDeleteOneClassArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneClassExerciseArgsDTO = {
  data: ClassExerciseCreateInputDTO;
};


export type MutationUpdateOneClassExerciseArgsDTO = {
  id: Scalars['String'];
  data: ClassExerciseUpdateInputDTO;
};


export type MutationDeleteOneClassExerciseArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateManyClassExercisesArgsDTO = {
  data: ClassExerciseCreateManyInputDTO;
};


export type MutationDeleteClassExercisesArgsDTO = {
  filter?: Maybe<ClassExerciseFilterDTO>;
};


export type MutationDeleteClassExerciseLinksArgsDTO = {
  filter?: Maybe<ClassExerciseLinkFilterDTO>;
};


export type MutationCreateOneClassLibraryArgsDTO = {
  data: ClassLibraryCreateInputDTO;
};


export type MutationUpdateOneClassLibraryArgsDTO = {
  id: Scalars['String'];
  data: ClassLibraryUpdateInputDTO;
};


export type MutationDeleteOneClassLibraryArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateManyClassLibrariesArgsDTO = {
  data: ClassLibraryCreateManyInputDTO;
};


export type MutationDeleteClassLibrariesArgsDTO = {
  filter?: Maybe<ClassLibraryFilterDTO>;
};


export type MutationCreateOneContentBlockArgsDTO = {
  data: ContentBlockCreateInputDTO;
};


export type MutationUpdateOneContentBlockArgsDTO = {
  id: Scalars['String'];
  data: ContentBlockUpdateInputDTO;
};


export type MutationDeleteOneContentBlockArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneDeviceArgsDTO = {
  data: DeviceCreateInputDTO;
};


export type MutationDeleteOneDeviceArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseArgsDTO = {
  data: ExerciseCreateInputDTO;
};


export type MutationUpdateOneExerciseArgsDTO = {
  id: Scalars['String'];
  data: ExerciseUpdateInputDTO;
};


export type MutationDeleteOneExerciseArgsDTO = {
  id: Scalars['String'];
};


export type MutationCompleteExerciseArgsDTO = {
  id: Scalars['String'];
  answers?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateOneExerciseAnswerArgsDTO = {
  data: ExerciseAnswerCreateInputDTO;
};


export type MutationUpdateOneExerciseAnswerArgsDTO = {
  id: Scalars['String'];
  data: ExerciseAnswerUpdateInputDTO;
};


export type MutationDeleteOneExerciseAnswerArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseCourseArgsDTO = {
  data: ExerciseCourseCreateInputDTO;
};


export type MutationUpdateOneExerciseCourseArgsDTO = {
  id: Scalars['String'];
  data: ExerciseCourseUpdateInputDTO;
};


export type MutationDeleteOneExerciseCourseArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseGradeArgsDTO = {
  data: ExerciseGradeCreateInputDTO;
};


export type MutationUpdateOneExerciseGradeArgsDTO = {
  id: Scalars['String'];
  data: ExerciseGradeUpdateInputDTO;
};


export type MutationDeleteOneExerciseGradeArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseQuestionArgsDTO = {
  data: ExerciseQuestionCreateInputDTO;
};


export type MutationUpdateOneExerciseQuestionArgsDTO = {
  id: Scalars['String'];
  data: ExerciseQuestionUpdateInputDTO;
};


export type MutationDeleteOneExerciseQuestionArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneExerciseTagArgsDTO = {
  data: ExerciseTagCreateInputDTO;
};


export type MutationUpdateOneExerciseTagArgsDTO = {
  id: Scalars['String'];
  data: ExerciseTagUpdateInputDTO;
};


export type MutationDeleteOneExerciseTagArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneHazardousLocationArgsDTO = {
  data: HazardousLocationCreateInputDTO;
};


export type MutationUpdateOneHazardousLocationArgsDTO = {
  id: Scalars['String'];
  data: HazardousLocationUpdateInputDTO;
};


export type MutationDeleteOneHazardousLocationArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneHazardousLocationReasonArgsDTO = {
  data: HazardousLocationReasonCreateInputDTO;
};


export type MutationUpdateOneHazardousLocationReasonArgsDTO = {
  id: Scalars['String'];
  data: HazardousLocationReasonUpdateInputDTO;
};


export type MutationDeleteOneHazardousLocationReasonArgsDTO = {
  id: Scalars['String'];
};


export type MutationPupilLoginArgsDTO = {
  classId: Scalars['String'];
  classNumber: Scalars['Int'];
  password: Scalars['String'];
};


export type MutationUsernamePupilLoginArgsDTO = {
  username: Scalars['String'];
  password: Scalars['String'];
};


export type MutationNfcPupilLoginArgsDTO = {
  nfcTagId: Scalars['String'];
  nfcTagType: Scalars['String'];
  validationCode: Scalars['String'];
};


export type MutationLoginArgsDTO = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateOneModuleArgsDTO = {
  data: ModuleCreateInputDTO;
};


export type MutationUpdateOneModuleArgsDTO = {
  id: Scalars['String'];
  data: ModuleUpdateInputDTO;
};


export type MutationSimulateOneModuleRegistrationArgsDTO = {
  data: ModuleRegistrationSimulateInputDTO;
};


export type MutationCreateOneMultiplierArgsDTO = {
  data: MultiplierCreateInputDTO;
};


export type MutationUpdateOneMultiplierArgsDTO = {
  id: Scalars['String'];
  data: MultiplierUpdateInputDTO;
};


export type MutationDeleteOneMultiplierArgsDTO = {
  id: Scalars['String'];
};


export type MutationResetPasswordArgsDTO = {
  email: Scalars['String'];
};


export type MutationCreateOneProjectArgsDTO = {
  data: ProjectCreateInputDTO;
};


export type MutationUpdateOneProjectArgsDTO = {
  id: Scalars['String'];
  data: ProjectUpdateInputDTO;
};


export type MutationDeleteOneProjectArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOnePupilArgsDTO = {
  data: PupilCreateInputDTO;
};


export type MutationUpdateOnePupilArgsDTO = {
  id: Scalars['String'];
  data: PupilUpdateInputDTO;
};


export type MutationDeleteOnePupilArgsDTO = {
  id: Scalars['String'];
};


export type MutationSaveZoneForPupilArgsDTO = {
  pupil: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};


export type MutationValidatePupilClassNumberArgsDTO = {
  data: ValidatePupilClassNumberInputDTO;
};


export type MutationCreateOnePupilCreditArgsDTO = {
  data: PupilCreditCreateInputDTO;
};


export type MutationUpdateOnePupilCreditArgsDTO = {
  id: Scalars['String'];
  data: PupilCreditUpdateInputDTO;
};


export type MutationDeleteOnePupilCreditArgsDTO = {
  id: Scalars['String'];
};


export type MutationUpdatePupilPasswordArgsDTO = {
  data: PupilPasswordUpdateInputDTO;
};


export type MutationUpdatePupilPasswordAnonymousArgsDTO = {
  data: PupilPasswordAnonymousUpdateInputDTO;
};


export type MutationPupilPasswordResetArgsDTO = {
  classId: Scalars['String'];
  classNumber: Scalars['Int'];
};


export type MutationValidatePupilTagArgsDTO = {
  data: ValidatePupilTagInputDTO;
};


export type MutationCreateOneRegimeArgsDTO = {
  data: RegimeCreateInputDTO;
};


export type MutationUpdateOneRegimeArgsDTO = {
  id: Scalars['String'];
  data: RegimeUpdateInputDTO;
};


export type MutationDeleteOneRegimeArgsDTO = {
  id: Scalars['String'];
};


export type MutationDeleteOneRegimeInactivityArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneRewardArgsDTO = {
  data: RewardCreateInputDTO;
};


export type MutationUpdateOneRewardArgsDTO = {
  id: Scalars['String'];
  data: RewardUpdateInputDTO;
};


export type MutationDeleteOneRewardArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneRewardQuantityArgsDTO = {
  data: RewardQuantityCreateInputDTO;
};


export type MutationUpdateOneRewardQuantityArgsDTO = {
  id: Scalars['String'];
  data: RewardQuantityUpdateInputDTO;
};


export type MutationDeleteOneRewardQuantityArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneRewardRequestArgsDTO = {
  reward: Scalars['String'];
};


export type MutationMarkOneRewardRequestAsReceivedArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneSchoolArgsDTO = {
  data: SchoolCreateInputDTO;
};


export type MutationUpdateOneSchoolArgsDTO = {
  id: Scalars['String'];
  data: SchoolUpdateInputDTO;
};


export type MutationDeleteOneSchoolArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneSchoolTrophyArgsDTO = {
  data: SchoolTrophyCreateInputDTO;
};


export type MutationUpdateOneSchoolTrophyArgsDTO = {
  id: Scalars['String'];
  data: SchoolTrophyUpdateInputDTO;
};


export type MutationDeleteOneSchoolTrophyArgsDTO = {
  id: Scalars['String'];
};


export type MutationCreateOneSchoolyearArgsDTO = {
  data: SchoolyearCreateInputDTO;
};


export type MutationUpdateOneSchoolyearArgsDTO = {
  id: Scalars['String'];
  data: SchoolyearUpdateInputDTO;
};


export type MutationDeleteOneSchoolyearArgsDTO = {
  id: Scalars['String'];
};


export type MutationGetOneAnonymousTokenArgsDTO = {
  data: AnonymousTokenInputDTO;
};


export type MutationCreateOneUserArgsDTO = {
  data: UserCreateInputDTO;
};


export type MutationUpdateOneUserArgsDTO = {
  id: Scalars['String'];
  data?: Maybe<UserUpdateInputDTO>;
};


export type MutationDeleteOneUserArgsDTO = {
  id: Scalars['String'];
};


export type MutationUpdatePasswordArgsDTO = {
  data: PasswordUpdateInputDTO;
};


export type MutationUpdatePasswordAnonymousArgsDTO = {
  data: PasswordAnonymousUpdateInputDTO;
};


export type MutationCreateOneZoneArgsDTO = {
  data: ZoneCreateInputDTO;
};


export type MutationUpdateOneZoneArgsDTO = {
  id: Scalars['String'];
  data: ZoneUpdateInputDTO;
};


export type MutationDeleteOneZoneArgsDTO = {
  id: Scalars['String'];
};

export type PagingDTO = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PasswordAnonymousUpdateInputDTO = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type PasswordUpdateInputDTO = {
  password: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type PolicyDTO = {
  __typename?: 'Policy';
  id: Scalars['String'];
  type: PolicyTypeDTO;
  version: Scalars['String'];
  url: Scalars['String'];
  required: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export type PolicyFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  type?: Maybe<PolicyTypeFilterDTO>;
  version?: Maybe<StringFilterDTO>;
  required?: Maybe<BooleanFilterDTO>;
  active?: Maybe<BooleanFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  AND?: Maybe<Array<PolicyFilterDTO>>;
  OR?: Maybe<Array<PolicyFilterDTO>>;
};

export enum PolicyTypeDTO {
  DataProcessingAgreementDTO = 'DATA_PROCESSING_AGREEMENT',
  PrivacyPolicyDTO = 'PRIVACY_POLICY',
  UnknownDTO = 'UNKNOWN'
}

export type PolicyTypeFilterDTO = {
  equals?: Maybe<PolicyTypeDTO>;
  not?: Maybe<PolicyTypeDTO>;
  in?: Maybe<Array<Maybe<PolicyTypeDTO>>>;
  notIn?: Maybe<Array<Maybe<PolicyTypeDTO>>>;
};

export type ProfileDTO = {
  __typename?: 'Profile';
  showDailyLoginPopup: Scalars['Boolean'];
  consecutiveDays: Scalars['Int'];
  pupil: PupilDTO;
  showZoneForm: Scalars['Boolean'];
};

export type ProjectDTO = {
  __typename?: 'Project';
  id: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
  kioskUrl?: Maybe<Scalars['String']>;
  challengesEnabled: Scalars['Boolean'];
  rewardsEnabled: Scalars['Boolean'];
  bikeRegistrationsEnabled: Scalars['Boolean'];
  highFiveRegistrationsEnabled: Scalars['Boolean'];
  fluoRegistrationsEnabled: Scalars['Boolean'];
  zonesEnabled: Scalars['Boolean'];
  challengeIcon: AssetDTO;
  challengeBackground: AssetDTO;
  challengeForeground: AssetDTO;
  image: AssetDTO;
  activeSchoolyear?: Maybe<SchoolyearDTO>;
  schoolyears?: Maybe<Array<SchoolyearDTO>>;
};

export type ProjectCreateInputDTO = {
  title: Scalars['String'];
  url: Scalars['String'];
  kioskUrl?: Maybe<Scalars['String']>;
  challengesEnabled?: Maybe<Scalars['Boolean']>;
  rewardsEnabled?: Maybe<Scalars['Boolean']>;
  bikeRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  fluoRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  zonesEnabled?: Maybe<Scalars['Boolean']>;
  challengeIcon: Scalars['String'];
  challengeBackground: Scalars['String'];
  challengeForeground: Scalars['String'];
  image: Scalars['String'];
};

export type ProjectFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<ProjectFilterDTO>>;
  OR?: Maybe<Array<ProjectFilterDTO>>;
};

export type ProjectUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  kioskUrl?: Maybe<Scalars['String']>;
  challengesEnabled?: Maybe<Scalars['Boolean']>;
  rewardsEnabled?: Maybe<Scalars['Boolean']>;
  bikeRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  fluoRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  zonesEnabled?: Maybe<Scalars['Boolean']>;
  challengeIcon?: Maybe<Scalars['String']>;
  challengeBackground?: Maybe<Scalars['String']>;
  challengeForeground?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

export type PupilDTO = {
  __typename?: 'Pupil';
  id: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  class: ClassDTO;
  classNumber?: Maybe<Scalars['Int']>;
  avatar?: Maybe<AvatarDTO>;
  pupilTotal?: Maybe<PupilTotalDTO>;
  distance?: Maybe<Scalars['Int']>;
  zone?: Maybe<ZoneDTO>;
  noRegistrations: Scalars['Boolean'];
  moduleRegistrations?: Maybe<Array<Maybe<ModuleRegistrationDTO>>>;
  moduleRegistrationsCount: Scalars['Int'];
  pupilTags?: Maybe<Array<Maybe<PupilTagDTO>>>;
  username?: Maybe<UsernameDTO>;
};


export type PupilModuleRegistrationsArgsDTO = {
  filter?: Maybe<ModuleRegistrationReferenceFilterDTO>;
};


export type PupilModuleRegistrationsCountArgsDTO = {
  filter?: Maybe<ModuleRegistrationReferenceFilterDTO>;
};

export type PupilCreateInputDTO = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  classNumber?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<AvatarCreateInputDTO>;
  noRegistrations?: Maybe<Scalars['Boolean']>;
  pupilTags?: Maybe<Array<Maybe<PupilTagInputDTO>>>;
  usernameId?: Maybe<Scalars['String']>;
  parents?: Maybe<Array<PupilParentInputDTO>>;
};

export type PupilCreditDTO = {
  __typename?: 'PupilCredit';
  id: Scalars['String'];
  type: Scalars['String'];
  credit: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  registration?: Maybe<ModuleRegistrationDTO>;
  pupil: PupilDTO;
  valid: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
};

export type PupilCreditCreateInputDTO = {
  credit: Scalars['Int'];
  pupil: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type PupilCreditFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  pupil?: Maybe<PupilFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  credit?: Maybe<IntFilterDTO>;
  valid?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<PupilCreditFilterDTO>>;
  OR?: Maybe<Array<PupilCreditFilterDTO>>;
};

export enum PupilCreditTimeSeriesGroupByDTO {
  ClassDTO = 'CLASS',
  PupilDTO = 'PUPIL',
  SchoolDTO = 'SCHOOL'
}

export type PupilCreditUpdateInputDTO = {
  credit?: Maybe<Scalars['Int']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type PupilExerciseAnswerDTO = {
  __typename?: 'PupilExerciseAnswer';
  id: Scalars['String'];
  pupil: PupilDTO;
  exercise: ExerciseDTO;
  question: ExerciseQuestionDTO;
  answer: ExerciseAnswerDTO;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PupilExerciseResultDTO = {
  __typename?: 'PupilExerciseResult';
  id: Scalars['String'];
  pupil: PupilDTO;
  maxScore: Scalars['Int'];
  score: Scalars['Int'];
  answers?: Maybe<Array<Maybe<PupilExerciseAnswerDTO>>>;
  exercise?: Maybe<ExerciseDTO>;
  createdAt: Scalars['DateTime'];
};

export type PupilExerciseResultFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  pupil?: Maybe<PupilFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  score?: Maybe<IntFilterDTO>;
  maxScore?: Maybe<IntFilterDTO>;
  exercise?: Maybe<ExerciseFilterDTO>;
  AND?: Maybe<Array<PupilExerciseResultFilterDTO>>;
  OR?: Maybe<Array<PupilExerciseResultFilterDTO>>;
};

export type PupilFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  class?: Maybe<ClassFilterDTO>;
  classNumber?: Maybe<IntFilterDTO>;
  firstName?: Maybe<StringFilterDTO>;
  lastName?: Maybe<StringFilterDTO>;
  fullName?: Maybe<StringFilterDTO>;
  username?: Maybe<StringFilterDTO>;
  distance?: Maybe<IntFilterDTO>;
  zone?: Maybe<BasicStringFilterDTO>;
  noRegistrations?: Maybe<BooleanFilterDTO>;
  pupilTag?: Maybe<PupilTagFilterDTO>;
  AND?: Maybe<Array<PupilFilterDTO>>;
  OR?: Maybe<Array<PupilFilterDTO>>;
};

export type PupilParentInputDTO = {
  email: Scalars['String'];
  communicationPreferences: Array<UserCommunicationPreferenceInputDTO>;
  acceptedPolicies?: Maybe<Array<AcceptedPolicyInputDTO>>;
};

export type PupilPasswordAnonymousUpdateInputDTO = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type PupilPasswordUpdateInputDTO = {
  password: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type PupilReferenceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  class?: Maybe<ClassReferenceFilterDTO>;
  classNumber?: Maybe<IntFilterDTO>;
  firstName?: Maybe<StringFilterDTO>;
  lastName?: Maybe<StringFilterDTO>;
  fullName?: Maybe<StringFilterDTO>;
  username?: Maybe<StringFilterDTO>;
  distance?: Maybe<IntFilterDTO>;
  zone?: Maybe<BasicStringFilterDTO>;
  noRegistrations?: Maybe<BooleanFilterDTO>;
  AND?: Maybe<Array<PupilReferenceFilterDTO>>;
  OR?: Maybe<Array<PupilReferenceFilterDTO>>;
};

export type PupilSearchItemDTO = SearchItemDTO & {
  __typename?: 'PupilSearchItem';
  type: SearchTypeDTO;
  pupil: PupilDTO;
};

export type PupilTagDTO = {
  __typename?: 'PupilTag';
  type: Scalars['String'];
  tag: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type PupilTagFilterDTO = {
  type: Scalars['String'];
  tag?: Maybe<StringArrayFilterDTO>;
};

export type PupilTagInputDTO = {
  type: Scalars['String'];
  tag: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type PupilTotalDTO = {
  __typename?: 'PupilTotal';
  id: Scalars['String'];
  pupil: PupilDTO;
  totalCredits: Scalars['Int'];
  totalRegistrations: Scalars['Int'];
  totalDistance: Scalars['Float'];
  updateAt: Scalars['DateTime'];
};

export type PupilTotalFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  pupil?: Maybe<PupilFilterDTO>;
  totalCredits?: Maybe<IntFilterDTO>;
  totalRegistrations?: Maybe<IntFilterDTO>;
  AND?: Maybe<Array<PupilTotalFilterDTO>>;
  OR?: Maybe<Array<PupilTotalFilterDTO>>;
};

export type PupilUpdateInputDTO = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  classNumber?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<AvatarCreateInputDTO>;
  noRegistrations?: Maybe<Scalars['Boolean']>;
  pupilTags?: Maybe<Array<Maybe<PupilTagInputDTO>>>;
  parents?: Maybe<Array<PupilParentInputDTO>>;
};

export type QueryDTO = {
  __typename?: 'Query';
  achievements: Array<AchievementDTO>;
  achievementsCount: Scalars['Int'];
  airQualities: Array<AirQualityDTO>;
  airQualitiesCount: Scalars['Int'];
  campaigns: Array<CampaignDTO>;
  campaignsCount: Scalars['Int'];
  challenges: Array<ChallengeDTO>;
  challengesCount: Scalars['Int'];
  classes: Array<ClassDTO>;
  classesCount: Scalars['Int'];
  classExercises: Array<ClassExerciseDTO>;
  classExercisesCount: Scalars['Int'];
  classLibraries: Array<ClassLibraryDTO>;
  classLibrariesCount: Scalars['Int'];
  contentBlocks: Array<ContentBlockDTO>;
  contentBlocksCount: Scalars['Int'];
  devices: Array<DeviceDTO>;
  devicesCount: Scalars['Int'];
  exercises: Array<ExerciseDTO>;
  exercisesCount: Scalars['Int'];
  exerciseCourses: Array<ExerciseCourseDTO>;
  exerciseCoursesCount: Scalars['Int'];
  exerciseGrades: Array<ExerciseGradeDTO>;
  exerciseGradesCount: Scalars['Int'];
  exerciseTags: Array<ExerciseTagDTO>;
  exerciseTagsCount: Scalars['Int'];
  hazardousLocations: Array<HazardousLocationDTO>;
  hazardousLocationsCount: Scalars['Int'];
  hazardousLocationReasons: Array<HazardousLocationReasonDTO>;
  hazardousLocationReasonsCount: Scalars['Int'];
  locations: Array<LocationDTO>;
  locationsCount: Scalars['Int'];
  modules: Array<ModuleDTO>;
  modulesCount: Scalars['Int'];
  moduleRegistrations: Array<ModuleRegistrationDTO>;
  moduleRegistrationsCount: Scalars['Int'];
  moduleRegistrationTimeSeries: Array<TimeSeriesDTO>;
  multipliers: Array<MultiplierDTO>;
  multipliersCount: Scalars['Int'];
  policies: Array<PolicyDTO>;
  profile: ProfileDTO;
  projects: Array<ProjectDTO>;
  projectsCount: Scalars['Int'];
  pupils: Array<PupilDTO>;
  pupilsCount: Scalars['Int'];
  pupilCredits: Array<PupilCreditDTO>;
  pupilCreditsCount: Scalars['Int'];
  pupilCreditTimeSeries: Array<TimeSeriesDTO>;
  pupilExerciseResults: Array<PupilExerciseResultDTO>;
  pupilExerciseResultsCount: Scalars['Int'];
  pupilTotals: Array<PupilTotalDTO>;
  pupilTotalsCount: Scalars['Int'];
  rawModuleMessages: Array<RawModuleMessageDTO>;
  rawModuleMessagesCount: Scalars['Int'];
  regimes: Array<RegimeDTO>;
  regimesCount: Scalars['Int'];
  rewards: Array<RewardDTO>;
  rewardsCount: Scalars['Int'];
  rewardQuantities: Array<RewardQuantityDTO>;
  rewardQuantitiesCount: Scalars['Int'];
  rewardRequests: Array<RewardRequestDTO>;
  rewardRequestsCount: Scalars['Int'];
  schools: Array<SchoolDTO>;
  schoolsCount: Scalars['Int'];
  schoolStatistics: Array<SchoolStatisticsDTO>;
  schoolTrophies: Array<SchoolTrophyDTO>;
  schoolTrophiesCount: Scalars['Int'];
  schoolyears: Array<SchoolyearDTO>;
  schoolyearsCount: Scalars['Int'];
  search: SearchResultDTO;
  users: Array<UserDTO>;
  userCount: Scalars['Int'];
  zones: Array<ZoneDTO>;
  zonesCount: Scalars['Int'];
};


export type QueryAchievementsArgsDTO = {
  filter?: Maybe<AchievementFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryAchievementsCountArgsDTO = {
  filter?: Maybe<AchievementFilterDTO>;
};


export type QueryAirQualitiesArgsDTO = {
  filter?: Maybe<AirQualityFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryAirQualitiesCountArgsDTO = {
  filter?: Maybe<AirQualityFilterDTO>;
};


export type QueryCampaignsArgsDTO = {
  filter?: Maybe<CampaignFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryCampaignsCountArgsDTO = {
  filter?: Maybe<CampaignFilterDTO>;
};


export type QueryChallengesArgsDTO = {
  filter?: Maybe<ChallengeFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryChallengesCountArgsDTO = {
  filter?: Maybe<ChallengeFilterDTO>;
};


export type QueryClassesArgsDTO = {
  filter?: Maybe<ClassFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryClassesCountArgsDTO = {
  filter?: Maybe<ClassFilterDTO>;
};


export type QueryClassExercisesArgsDTO = {
  filter?: Maybe<ClassExerciseFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryClassExercisesCountArgsDTO = {
  filter?: Maybe<ClassExerciseFilterDTO>;
};


export type QueryClassLibrariesArgsDTO = {
  filter?: Maybe<ClassLibraryFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryClassLibrariesCountArgsDTO = {
  filter?: Maybe<ClassLibraryFilterDTO>;
};


export type QueryContentBlocksArgsDTO = {
  filter?: Maybe<ContentBlockFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryContentBlocksCountArgsDTO = {
  filter?: Maybe<ContentBlockFilterDTO>;
};


export type QueryDevicesArgsDTO = {
  filter?: Maybe<DeviceFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryDevicesCountArgsDTO = {
  filter?: Maybe<DeviceFilterDTO>;
};


export type QueryExercisesArgsDTO = {
  filter?: Maybe<ExerciseFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryExercisesCountArgsDTO = {
  filter?: Maybe<ExerciseFilterDTO>;
};


export type QueryExerciseCoursesArgsDTO = {
  filter?: Maybe<ExerciseCourseFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryExerciseCoursesCountArgsDTO = {
  filter?: Maybe<ExerciseCourseFilterDTO>;
};


export type QueryExerciseGradesArgsDTO = {
  filter?: Maybe<ExerciseGradeFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryExerciseGradesCountArgsDTO = {
  filter?: Maybe<ExerciseGradeFilterDTO>;
};


export type QueryExerciseTagsArgsDTO = {
  filter?: Maybe<ExerciseTagFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryExerciseTagsCountArgsDTO = {
  filter?: Maybe<ExerciseTagFilterDTO>;
};


export type QueryHazardousLocationsArgsDTO = {
  filter?: Maybe<HazardousLocationFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryHazardousLocationsCountArgsDTO = {
  filter?: Maybe<HazardousLocationFilterDTO>;
};


export type QueryHazardousLocationReasonsArgsDTO = {
  filter?: Maybe<HazardousLocationReasonFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryHazardousLocationReasonsCountArgsDTO = {
  filter?: Maybe<HazardousLocationReasonFilterDTO>;
};


export type QueryLocationsArgsDTO = {
  filter?: Maybe<LocationFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryLocationsCountArgsDTO = {
  filter?: Maybe<LocationFilterDTO>;
};


export type QueryModulesArgsDTO = {
  filter?: Maybe<ModuleFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryModulesCountArgsDTO = {
  filter?: Maybe<ModuleFilterDTO>;
};


export type QueryModuleRegistrationsArgsDTO = {
  filter?: Maybe<ModuleRegistrationFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryModuleRegistrationsCountArgsDTO = {
  filter?: Maybe<ModuleRegistrationFilterDTO>;
};


export type QueryModuleRegistrationTimeSeriesArgsDTO = {
  bucketWidth: Scalars['String'];
  groupBy: ModuleRegistrationTimeSeriesGroupByDTO;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  filter?: Maybe<ModuleRegistrationFilterDTO>;
};


export type QueryMultipliersArgsDTO = {
  filter?: Maybe<MultiplierFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryMultipliersCountArgsDTO = {
  filter?: Maybe<MultiplierFilterDTO>;
};


export type QueryPoliciesArgsDTO = {
  filter?: Maybe<PolicyFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryProjectsArgsDTO = {
  filter?: Maybe<ProjectFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryProjectsCountArgsDTO = {
  filter?: Maybe<ProjectFilterDTO>;
};


export type QueryPupilsArgsDTO = {
  filter?: Maybe<PupilFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryPupilsCountArgsDTO = {
  filter?: Maybe<PupilFilterDTO>;
};


export type QueryPupilCreditsArgsDTO = {
  filter?: Maybe<PupilCreditFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryPupilCreditsCountArgsDTO = {
  filter?: Maybe<PupilCreditFilterDTO>;
};


export type QueryPupilCreditTimeSeriesArgsDTO = {
  bucketWidth: Scalars['String'];
  groupBy: PupilCreditTimeSeriesGroupByDTO;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  filter?: Maybe<PupilCreditFilterDTO>;
};


export type QueryPupilExerciseResultsArgsDTO = {
  filter?: Maybe<PupilExerciseResultFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryPupilExerciseResultsCountArgsDTO = {
  filter?: Maybe<PupilExerciseResultFilterDTO>;
};


export type QueryPupilTotalsArgsDTO = {
  filter?: Maybe<PupilTotalFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryPupilTotalsCountArgsDTO = {
  filter?: Maybe<PupilTotalFilterDTO>;
};


export type QueryRawModuleMessagesArgsDTO = {
  filter?: Maybe<RawModuleMessageFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryRawModuleMessagesCountArgsDTO = {
  filter?: Maybe<RawModuleMessageFilterDTO>;
};


export type QueryRegimesArgsDTO = {
  filter?: Maybe<RegimeFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryRegimesCountArgsDTO = {
  filter?: Maybe<RegimeFilterDTO>;
};


export type QueryRewardsArgsDTO = {
  filter?: Maybe<RewardFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryRewardsCountArgsDTO = {
  filter?: Maybe<RewardFilterDTO>;
};


export type QueryRewardQuantitiesArgsDTO = {
  filter?: Maybe<RewardQuantityFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryRewardQuantitiesCountArgsDTO = {
  filter?: Maybe<RewardQuantityFilterDTO>;
};


export type QueryRewardRequestsArgsDTO = {
  filter?: Maybe<RewardRequestFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryRewardRequestsCountArgsDTO = {
  filter?: Maybe<RewardRequestFilterDTO>;
};


export type QuerySchoolsArgsDTO = {
  filter?: Maybe<SchoolFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QuerySchoolsCountArgsDTO = {
  filter?: Maybe<SchoolFilterDTO>;
};


export type QuerySchoolStatisticsArgsDTO = {
  filter?: Maybe<SchoolStatisticsFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QuerySchoolTrophiesArgsDTO = {
  filter?: Maybe<SchoolTrophyFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QuerySchoolTrophiesCountArgsDTO = {
  filter?: Maybe<SchoolTrophyFilterDTO>;
};


export type QuerySchoolyearsArgsDTO = {
  filter?: Maybe<SchoolyearFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QuerySchoolyearsCountArgsDTO = {
  filter?: Maybe<SchoolyearFilterDTO>;
};


export type QuerySearchArgsDTO = {
  search: Scalars['String'];
  filter?: Maybe<SearchFilterDTO>;
};


export type QueryUsersArgsDTO = {
  filter?: Maybe<UserFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryUserCountArgsDTO = {
  filter?: Maybe<UserFilterDTO>;
};


export type QueryZonesArgsDTO = {
  filter?: Maybe<ZoneFilterDTO>;
  paging?: Maybe<PagingDTO>;
  sort?: Maybe<Array<Maybe<SortDTO>>>;
};


export type QueryZonesCountArgsDTO = {
  filter?: Maybe<ZoneFilterDTO>;
};

export type RawModuleMessageDTO = {
  __typename?: 'RawModuleMessage';
  id: Scalars['String'];
  deviceId: Scalars['String'];
  data: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  receivedAt: Scalars['DateTime'];
};

export type RawModuleMessageDataFilterDTO = {
  nfcId?: Maybe<StringFilterDTO>;
};

export type RawModuleMessageFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  deviceId?: Maybe<StringFilterDTO>;
  data?: Maybe<RawModuleMessageDataFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  receivedAt?: Maybe<DateTimeFilterDTO>;
  AND?: Maybe<Array<RawModuleMessageFilterDTO>>;
  OR?: Maybe<Array<RawModuleMessageFilterDTO>>;
};

export type RegimeDTO = {
  __typename?: 'Regime';
  id: Scalars['String'];
  title: Scalars['String'];
  days: Array<RegimeDayDTO>;
  inactivities: Array<RegimeInactivityDTO>;
  type: RegimeTypeDTO;
  project?: Maybe<ProjectDTO>;
  template?: Maybe<RegimeDTO>;
  overrideTemplateDays?: Maybe<Scalars['Boolean']>;
};

export type RegimeCreateInputDTO = {
  title: Scalars['String'];
  days: Array<RegimeDayInputDTO>;
  inactivities: Array<RegimeInactivityInputDTO>;
  project?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  overrideTemplateDays?: Maybe<Scalars['Boolean']>;
};

export type RegimeDayDTO = {
  __typename?: 'RegimeDay';
  id: Scalars['String'];
  weekDay: Scalars['Int'];
  hours: Array<RegimeHourDTO>;
};

export type RegimeDayInputDTO = {
  id?: Maybe<Scalars['String']>;
  weekDay: Scalars['Int'];
  hours: Array<RegimeHourInputDTO>;
};

export type RegimeFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  type?: Maybe<RegimeTypeFilterDTO>;
  project?: Maybe<BasicStringFilterDTO>;
  template?: Maybe<BasicStringFilterDTO>;
  AND?: Maybe<Array<RegimeFilterDTO>>;
  OR?: Maybe<Array<RegimeFilterDTO>>;
};

export type RegimeHourDTO = {
  __typename?: 'RegimeHour';
  id: Scalars['String'];
  fromHours: Scalars['Int'];
  toHours: Scalars['Int'];
};

export type RegimeHourInputDTO = {
  id?: Maybe<Scalars['String']>;
  fromHours: Scalars['Int'];
  toHours: Scalars['Int'];
};

export type RegimeInactivityDTO = {
  __typename?: 'RegimeInactivity';
  id: Scalars['String'];
  title: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  appliedForAllSchools: Scalars['Boolean'];
  schools?: Maybe<Array<SchoolDTO>>;
};

export type RegimeInactivityInputDTO = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  appliedForAllSchools?: Maybe<Scalars['Boolean']>;
  schools?: Maybe<Array<Scalars['String']>>;
};

export enum RegimeTypeDTO {
  GlobalDTO = 'GLOBAL',
  FromTemplateDTO = 'FROM_TEMPLATE'
}

export type RegimeTypeFilterDTO = {
  equals?: Maybe<RegimeTypeDTO>;
  not?: Maybe<RegimeTypeDTO>;
  in?: Maybe<Array<Maybe<RegimeTypeDTO>>>;
  notIn?: Maybe<Array<Maybe<RegimeTypeDTO>>>;
};

export type RegimeUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  days?: Maybe<Array<RegimeDayInputDTO>>;
  inactivities?: Maybe<Array<RegimeInactivityInputDTO>>;
  template?: Maybe<Scalars['String']>;
  overrideTemplateDays?: Maybe<Scalars['Boolean']>;
};

export type RewardDTO = {
  __typename?: 'Reward';
  id: Scalars['String'];
  title: Scalars['String'];
  isRewardedMultipleTimes: Scalars['Boolean'];
  availableFrom: Scalars['DateTime'];
  availableTo: Scalars['DateTime'];
  collectInstructions: Scalars['String'];
  image: AssetDTO;
  rewardAssignments: Array<RewardAssignmentDTO>;
  valueInCredits: Scalars['Int'];
  rewardRequests?: Maybe<Array<Maybe<RewardRequestDTO>>>;
  rewardRequestsCount: Scalars['Int'];
  rewardQuantities: Array<RewardQuantityDTO>;
  schoolyear: SchoolyearDTO;
  schoolSpecific: Scalars['Boolean'];
};


export type RewardRewardRequestsArgsDTO = {
  filter?: Maybe<RewardRequestReferenceFilterDTO>;
};


export type RewardRewardRequestsCountArgsDTO = {
  filter?: Maybe<RewardRequestReferenceFilterDTO>;
};

export type RewardAssignmentDTO = {
  __typename?: 'RewardAssignment';
  id: Scalars['String'];
  email: Array<Scalars['String']>;
  reward: RewardDTO;
  school: SchoolDTO;
  userId: Array<Scalars['String']>;
  amount: Scalars['Int'];
  overrideCollectInstructions: Scalars['Boolean'];
  collectInstructions?: Maybe<Scalars['String']>;
  overrideRewardedMultipleTimes: Scalars['Boolean'];
  isRewardedMultipleTimes?: Maybe<Scalars['Boolean']>;
  overrideValueInCredits: Scalars['Boolean'];
  valueInCredits?: Maybe<Scalars['Int']>;
};

export type RewardAssignmentCreateInputDTO = {
  email: Array<Scalars['String']>;
  school: Scalars['String'];
  userId: Array<Scalars['String']>;
  amount: Scalars['Int'];
  overrideCollectInstructions: Scalars['Boolean'];
  collectInstructions?: Maybe<Scalars['String']>;
  overrideRewardedMultipleTimes: Scalars['Boolean'];
  isRewardedMultipleTimes?: Maybe<Scalars['Boolean']>;
  overrideValueInCredits: Scalars['Boolean'];
  valueInCredits?: Maybe<Scalars['Int']>;
};

export type RewardCreateInputDTO = {
  title: Scalars['String'];
  isRewardedMultipleTimes: Scalars['Boolean'];
  availableFrom: Scalars['DateTime'];
  availableTo: Scalars['DateTime'];
  collectInstructions: Scalars['String'];
  image: Scalars['String'];
  rewardAssignments?: Maybe<Array<Maybe<RewardAssignmentCreateInputDTO>>>;
  valueInCredits: Scalars['Int'];
  schoolyear: Scalars['String'];
};

export type RewardFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  availableFrom?: Maybe<DateTimeFilterDTO>;
  availableTo?: Maybe<DateTimeFilterDTO>;
  schoolSpecific?: Maybe<BooleanFilterDTO>;
  schoolyear?: Maybe<SchoolyearFilterDTO>;
  school?: Maybe<BasicStringFilterDTO>;
  AND?: Maybe<Array<RewardFilterDTO>>;
  OR?: Maybe<Array<RewardFilterDTO>>;
};

export type RewardQuantityDTO = {
  __typename?: 'RewardQuantity';
  id: Scalars['String'];
  type: RewardQuantityTypeDTO;
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  reward: RewardDTO;
  school: SchoolDTO;
};

export type RewardQuantityChangeCreateInputDTO = {
  type: RewardQuantityChangeTypeDTO;
  amount: Scalars['Int'];
};

export enum RewardQuantityChangeTypeDTO {
  AdditionalOrderDTO = 'ADDITIONAL_ORDER',
  RecountDTO = 'RECOUNT'
}

export type RewardQuantityCreateInputDTO = {
  type: RewardQuantityTypeDTO;
  amount: Scalars['Int'];
  reward: Scalars['String'];
  school: Scalars['String'];
};

export type RewardQuantityFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  reward?: Maybe<BasicStringFilterDTO>;
  school?: Maybe<BasicStringFilterDTO>;
  AND?: Maybe<Array<RewardQuantityFilterDTO>>;
  OR?: Maybe<Array<RewardQuantityFilterDTO>>;
};

export enum RewardQuantityTypeDTO {
  InitialDTO = 'INITIAL',
  AdditionalOrderDTO = 'ADDITIONAL_ORDER',
  RecountDTO = 'RECOUNT'
}

export type RewardQuantityUpdateInputDTO = {
  amount?: Maybe<Scalars['Int']>;
};

export type RewardRequestDTO = {
  __typename?: 'RewardRequest';
  id: Scalars['String'];
  issuedBy?: Maybe<Scalars['String']>;
  issuedByName?: Maybe<Scalars['String']>;
  pupil: PupilDTO;
  receivedAt?: Maybe<Scalars['DateTime']>;
  requestedAt: Scalars['DateTime'];
  reward: RewardDTO;
};

export type RewardRequestFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  pupil?: Maybe<PupilFilterDTO>;
  reward?: Maybe<BasicStringFilterDTO>;
  receivedAt?: Maybe<DateTimeFilterDTO>;
  AND?: Maybe<Array<RewardRequestFilterDTO>>;
  OR?: Maybe<Array<RewardRequestFilterDTO>>;
};

export type RewardRequestReferenceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  pupil?: Maybe<PupilReferenceFilterDTO>;
  reward?: Maybe<BasicStringFilterDTO>;
  receivedAt?: Maybe<DateTimeFilterDTO>;
  AND?: Maybe<Array<RewardRequestReferenceFilterDTO>>;
  OR?: Maybe<Array<RewardRequestReferenceFilterDTO>>;
};

export type RewardSearchItemDTO = SearchItemDTO & {
  __typename?: 'RewardSearchItem';
  type: SearchTypeDTO;
  reward: RewardDTO;
};

export type RewardUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  isRewardedMultipleTimes?: Maybe<Scalars['Boolean']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableTo?: Maybe<Scalars['DateTime']>;
  collectInstructions?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  rewardAssignments?: Maybe<Array<Maybe<RewardAssignmentCreateInputDTO>>>;
  valueInCredits?: Maybe<Scalars['Int']>;
};

export enum RoleDTO {
  AdminDTO = 'ADMIN',
  ProjectManagerDTO = 'PROJECT_MANAGER',
  PrincipalDTO = 'PRINCIPAL',
  TeacherDTO = 'TEACHER',
  ParentDTO = 'PARENT',
  InstallerDTO = 'INSTALLER'
}

export type SchoolDTO = {
  __typename?: 'School';
  id: Scalars['String'];
  title: Scalars['String'];
  location: LocationDTO;
  classes: Array<ClassDTO>;
  schoolyear: SchoolyearDTO;
  schoolTotal: SchoolTotalDTO;
  bikeRegistrationsEnabled: Scalars['Boolean'];
  highFiveRegistrationsEnabled: Scalars['Boolean'];
  fluoRegistrationsEnabled: Scalars['Boolean'];
  zonesEnabled: Scalars['Boolean'];
  radius: Scalars['Int'];
  logo?: Maybe<AssetDTO>;
  website?: Maybe<Scalars['String']>;
  pupilsCount: Scalars['Int'];
  moduleRegistrationsCount: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
};


export type SchoolModuleRegistrationsCountArgsDTO = {
  filter?: Maybe<ModuleRegistrationReferenceFilterDTO>;
};

export type SchoolCreateInputDTO = {
  title: Scalars['String'];
  location: LocationCreateInputDTO;
  schoolyear: Scalars['String'];
  bikeRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  fluoRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  zonesEnabled?: Maybe<Scalars['Boolean']>;
  radius: Scalars['Int'];
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  code: Scalars['String'];
};

export type SchoolFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  schoolyear?: Maybe<SchoolyearFilterDTO>;
  code?: Maybe<StringFilterDTO>;
  AND?: Maybe<Array<SchoolFilterDTO>>;
  OR?: Maybe<Array<SchoolFilterDTO>>;
};

export type SchoolReferenceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  schoolyear?: Maybe<SchoolyearReferenceFilterDTO>;
  AND?: Maybe<Array<SchoolReferenceFilterDTO>>;
  OR?: Maybe<Array<SchoolReferenceFilterDTO>>;
};

export type SchoolSearchItemDTO = SearchItemDTO & {
  __typename?: 'SchoolSearchItem';
  type: SearchTypeDTO;
  school: SchoolDTO;
};

export type SchoolStatisticsDTO = {
  __typename?: 'SchoolStatistics';
  school: SchoolDTO;
  pupilParticipationRate: Scalars['Float'];
};

export type SchoolStatisticsFilterDTO = {
  school?: Maybe<SchoolFilterDTO>;
  AND?: Maybe<Array<SchoolStatisticsFilterDTO>>;
  OR?: Maybe<Array<SchoolStatisticsFilterDTO>>;
};

export type SchoolTotalDTO = {
  __typename?: 'SchoolTotal';
  id: Scalars['String'];
  school: SchoolDTO;
  totalCredits: Scalars['Int'];
  totalRegistrations: Scalars['Int'];
  totalDistance: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  totalCO2: Scalars['Float'];
  totalFuel: Scalars['Float'];
};

export type SchoolTrophyDTO = {
  __typename?: 'SchoolTrophy';
  id: Scalars['String'];
  title: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  school: SchoolDTO;
  statistics: Array<SchoolTrophyStatisticsDTO>;
};

export type SchoolTrophyCreateInputDTO = {
  title: Scalars['String'];
  startsAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  school: Scalars['String'];
};

export type SchoolTrophyFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  title?: Maybe<StringFilterDTO>;
  startsAt?: Maybe<DateTimeFilterDTO>;
  endsAt?: Maybe<DateTimeFilterDTO>;
  school?: Maybe<SchoolFilterDTO>;
  AND?: Maybe<Array<SchoolTrophyFilterDTO>>;
  OR?: Maybe<Array<SchoolTrophyFilterDTO>>;
};

export type SchoolTrophyStatisticsDTO = {
  __typename?: 'SchoolTrophyStatistics';
  class: ClassDTO;
  averageModuleRegistrations: Scalars['Float'];
};

export type SchoolTrophyUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
};

export type SchoolUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  location?: Maybe<LocationCreateInputDTO>;
  schoolyear?: Maybe<Scalars['String']>;
  bikeRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  highFiveRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  fluoRegistrationsEnabled?: Maybe<Scalars['Boolean']>;
  zonesEnabled?: Maybe<Scalars['Boolean']>;
  radius?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type SchoolyearDTO = {
  __typename?: 'Schoolyear';
  id: Scalars['String'];
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  projectGoal: Scalars['Int'];
  pupilGoal: Scalars['Int'];
  title: Scalars['String'];
  active: Scalars['Boolean'];
  project: ProjectDTO;
  contentBlocks?: Maybe<Array<Maybe<ContentBlockDTO>>>;
  schools: Array<SchoolDTO>;
  schoolyearTotal: SchoolyearTotalDTO;
};

export type SchoolyearCreateInputDTO = {
  endsAt: Scalars['DateTime'];
  startsAt: Scalars['DateTime'];
  projectGoal: Scalars['Int'];
  pupilGoal: Scalars['Int'];
  title: Scalars['String'];
  project: Scalars['String'];
};

export type SchoolyearFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  project?: Maybe<BasicStringFilterDTO>;
  active?: Maybe<BooleanFilterDTO>;
  startsAt?: Maybe<DateTimeFilterDTO>;
  endsAt?: Maybe<DateTimeFilterDTO>;
  AND?: Maybe<Array<SchoolyearFilterDTO>>;
  OR?: Maybe<Array<SchoolyearFilterDTO>>;
};

export type SchoolyearReferenceFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  project?: Maybe<BasicStringFilterDTO>;
  active?: Maybe<BooleanFilterDTO>;
  startsAt?: Maybe<DateTimeFilterDTO>;
  endsAt?: Maybe<DateTimeFilterDTO>;
  AND?: Maybe<Array<SchoolyearReferenceFilterDTO>>;
  OR?: Maybe<Array<SchoolyearReferenceFilterDTO>>;
};

export type SchoolyearTotalDTO = {
  __typename?: 'SchoolyearTotal';
  id: Scalars['String'];
  schoolyear: SchoolyearDTO;
  totalCredits: Scalars['Int'];
  totalRegistrations: Scalars['Int'];
  totalDistance: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  totalCO2: Scalars['Float'];
  totalFuel: Scalars['Float'];
  averageFuelPerPupilPerMonth: Scalars['Float'];
};

export type SchoolyearUpdateInputDTO = {
  endsAt?: Maybe<Scalars['DateTime']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  projectGoal?: Maybe<Scalars['Int']>;
  pupilGoal?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
};

export type SearchFilterDTO = {
  types?: Maybe<Array<SearchTypeDTO>>;
};

export type SearchItemDTO = {
  type: SearchTypeDTO;
};

export type SearchResultDTO = {
  __typename?: 'SearchResult';
  countByType: Array<SearchResultCountByTypeDTO>;
  items: Array<SearchItemDTO>;
};

export type SearchResultCountByTypeDTO = {
  __typename?: 'SearchResultCountByType';
  type: SearchTypeDTO;
  count: Scalars['Int'];
};

export enum SearchTypeDTO {
  PupilDTO = 'PUPIL',
  ClassDTO = 'CLASS',
  SchoolDTO = 'SCHOOL',
  ChallengeDTO = 'CHALLENGE',
  RewardDTO = 'REWARD',
  ExerciseDTO = 'EXERCISE'
}

export type SortDTO = {
  field: Scalars['String'];
  order: SortOrderDTO;
};

export enum SortOrderDTO {
  AscDTO = 'ASC',
  DescDTO = 'DESC'
}

export type StringArrayFilterDTO = {
  containsSome?: Maybe<Array<Maybe<Scalars['String']>>>;
  containsAll?: Maybe<Array<Maybe<Scalars['String']>>>;
  equals?: Maybe<Array<Maybe<Scalars['String']>>>;
  someContains?: Maybe<Scalars['String']>;
  allContains?: Maybe<Scalars['String']>;
  someStartsWith?: Maybe<Scalars['String']>;
  allStartsWith?: Maybe<Scalars['String']>;
  someEndsWith?: Maybe<Scalars['String']>;
  allEndsWith?: Maybe<Scalars['String']>;
};

export type StringFilterDTO = {
  equals?: Maybe<Scalars['String']>;
  not?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
};

export type SubmitResultDTO = {
  __typename?: 'SubmitResult';
  amountOfQuestions: Scalars['Int'];
  amountCorrect: Scalars['Int'];
  coinsEarned: Scalars['Int'];
};

export type SubscriptionDTO = {
  __typename?: 'Subscription';
  rawModuleMessageCreated: RawModuleMessageDTO;
};


export type SubscriptionRawModuleMessageCreatedArgsDTO = {
  filter?: Maybe<RawModuleMessageFilterDTO>;
};

export type SuccessDTO = {
  __typename?: 'Success';
  success: Scalars['Boolean'];
};


export type TimeSeriesDTO = {
  __typename?: 'TimeSeries';
  id: Scalars['String'];
  name: Scalars['String'];
  columns: Array<Scalars['String']>;
  points: Array<TimeSeriesPointDTO>;
};

export type TimeSeriesPointDTO = {
  __typename?: 'TimeSeriesPoint';
  values: Array<Scalars['String']>;
};

export type TimeSeriesResultDTO = {
  __typename?: 'TimeSeriesResult';
  timestamps?: Maybe<Array<Maybe<Scalars['String']>>>;
  series?: Maybe<Array<Maybe<TimeSeriesDTO>>>;
};

export type TokenResultDTO = {
  __typename?: 'TokenResult';
  token?: Maybe<Scalars['String']>;
};


export type UserDTO = {
  __typename?: 'User';
  id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  emailValidated?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  roles?: Maybe<Array<UserRoleDTO>>;
  lockedUntil?: Maybe<Scalars['DateTime']>;
  lockedFrom?: Maybe<Scalars['DateTime']>;
  resourceAccess?: Maybe<Array<UserResourceAccessDTO>>;
};

export enum UserCommunicationChannelDTO {
  EmailDTO = 'EMAIL'
}

export type UserCommunicationPreferenceDTO = {
  __typename?: 'UserCommunicationPreference';
  channel: UserCommunicationChannelDTO;
  type: UserCommunicationPreferenceCommunicationTypeDTO;
  allow: Scalars['Boolean'];
};

export enum UserCommunicationPreferenceCommunicationTypeDTO {
  RewardsAndUpdatesForChildDTO = 'REWARDS_AND_UPDATES_FOR_CHILD'
}

export type UserCommunicationPreferenceInputDTO = {
  channel: UserCommunicationChannelDTO;
  type: UserCommunicationPreferenceCommunicationTypeDTO;
  allow: Scalars['Boolean'];
};

export type UserCreateInputDTO = {
  email: Scalars['String'];
  emailValidated: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roles: Array<UserRoleCreateInputDTO>;
  lockedUntil?: Maybe<Scalars['DateTime']>;
  lockedFrom?: Maybe<Scalars['DateTime']>;
  resourceAccess: Array<UserResourceAccessCreateInputDTO>;
};

export type UserFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  email?: Maybe<StringFilterDTO>;
  emailValidated?: Maybe<BooleanFilterDTO>;
  firstName?: Maybe<StringFilterDTO>;
  lastName?: Maybe<StringFilterDTO>;
  lockedUntil?: Maybe<DateTimeFilterDTO>;
  lockedFrom?: Maybe<Scalars['DateTime']>;
};

export type UserResourceAccessDTO = {
  __typename?: 'UserResourceAccess';
  projectId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  pupilId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type UserResourceAccessCreateInputDTO = {
  projectId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  pupilId?: Maybe<Scalars['String']>;
};

export type UserResourceAccessFilterDTO = {
  projectId?: Maybe<StringFilterDTO>;
  schoolId?: Maybe<StringFilterDTO>;
  classId?: Maybe<StringFilterDTO>;
  pupilId?: Maybe<StringFilterDTO>;
  createdAt?: Maybe<StringFilterDTO>;
};

export type UserResourceAccessUpdateInputDTO = {
  projectId: Scalars['String'];
  schoolId?: Maybe<Scalars['String']>;
  classId?: Maybe<Scalars['String']>;
  pupilId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type UserRoleDTO = {
  __typename?: 'UserRole';
  role: RoleDTO;
  projectId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type UserRoleCreateInputDTO = {
  role: RoleDTO;
  projectId: Scalars['String'];
};

export type UserRoleFilterDTO = {
  role?: Maybe<UserRoleRoleFilterDTO>;
  createdAt?: Maybe<DateTimeFilterDTO>;
  projectId?: Maybe<StringFilterDTO>;
};

export type UserRoleRoleFilterDTO = {
  equals?: Maybe<RoleDTO>;
  in?: Maybe<Array<Maybe<RoleDTO>>>;
};

export type UserRoleUpdateInputDTO = {
  role: RoleDTO;
  projectId: Scalars['String'];
};

export type UserUpdateInputDTO = {
  email?: Maybe<Scalars['String']>;
  emailValidated?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRoleUpdateInputDTO>>;
  lockedUntil?: Maybe<Scalars['DateTime']>;
  lockedFrom?: Maybe<Scalars['DateTime']>;
  resourceAccess?: Maybe<Array<UserResourceAccessUpdateInputDTO>>;
};

export type UsernameDTO = {
  __typename?: 'Username';
  username: Scalars['String'];
  seed: Scalars['String'];
  id: Scalars['String'];
};

export type ValidatePupilClassNumberInputDTO = {
  class: Scalars['String'];
  classNumber: Scalars['Int'];
};

export type ValidatePupilTagInputDTO = {
  tag: PupilTagInputDTO;
  schoolyearIds?: Maybe<Array<Scalars['String']>>;
};

export type ZoneDTO = {
  __typename?: 'Zone';
  id: Scalars['String'];
  title: Scalars['String'];
  color: Scalars['String'];
  school: SchoolDTO;
  geoFencePoints: Array<ZoneGeoFencePointDTO>;
};

export type ZoneCreateInputDTO = {
  title: Scalars['String'];
  color: Scalars['String'];
  school: Scalars['String'];
  geoFencePoints: Array<ZoneGeoFencePointInputDTO>;
};

export type ZoneFilterDTO = {
  id?: Maybe<StringFilterDTO>;
  school?: Maybe<BasicStringFilterDTO>;
  AND?: Maybe<Array<ZoneFilterDTO>>;
  OR?: Maybe<Array<ZoneFilterDTO>>;
};

export type ZoneGeoFencePointDTO = {
  __typename?: 'ZoneGeoFencePoint';
  id: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ZoneGeoFencePointInputDTO = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type ZoneUpdateInputDTO = {
  title?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  geoFencePoints?: Maybe<Array<ZoneGeoFencePointInputDTO>>;
};
