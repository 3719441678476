import styled from "styled-components";

export const ReferenceScreenStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
		max-width: 500px;
	}
`;
