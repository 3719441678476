import {
	DeepPartial, IOMap, ToAPI, ToInternal,
} from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { ModuleDTO, QueryDTO } from "models/schema";
import { Module } from "./models/module";
import { UpdateModuleMutationFnDTO } from "./models/updateModule";
import { ModulesAPI, UpdateModuleMutation } from "./useModulesUseCases";

export type ModulesAPIDTO = ApolloAPI<QueryDTO, "modules">;

interface Mapper {
	toAPI: ToAPI<ModulesAPIDTO, ModulesAPI>;
	toUpdateMutation: ToInternal<UpdateModuleMutationFnDTO, UpdateModuleMutation>;
	toModule: ToInternal<DeepPartial<ModuleDTO>, Module>;
}

export class ModuleMapper implements Mapper {
	public toModule: Mapper["toModule"] = (module) => ({
		id: module.id || "",
		deviceId: module.deviceId || "",
		title: module.title || "",
		reference: module.reference || "",
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: ModulesAPIDTO["data"]): DeepPartial<ModuleDTO[]> => {
			if (data?.modules) {
				return data.modules;
			}

			return [];
		};

		const data = extractDTOs(api?.data);

		return {
			...api,
			isLoading: api?.loading || false,
			data: Array.isArray(data) ? data.map(this.toModule) : data,
		};
	};

	public toUpdateMutation: Mapper["toUpdateMutation"] = (mutation) => {
		const map: IOMap<UpdateModuleMutationFnDTO, UpdateModuleMutation> = {
			toInput: (input) => ({
				variables: {
					id: input.id,
					data: {
						deviceId: input.deviceId,
						location: {
							lat: input.lat,
							lng: input.lng,
						},
						reference: input.reference || undefined,
					},
				},
			}),
			toOutput: (output) => ((output.data?.updateOneModule ? this.toModule(output.data.updateOneModule) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
