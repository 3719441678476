import Colors from "style/colors";
import styled from "styled-components";

const HeaderStyle = styled.header`
	display: flex;
	justify-content: space-between;
	background-color: ${Colors.highFiveBlue};
	width: 100vw;
	height: 50px;
	padding: 10px;

	button {
		width: auto;
		height: auto;
		padding: 7px;
	}

	img {
		height: 100%;
	}

	.buttons {
		display: flex;
		justify-content: center;

		button {
			margin-left: 10px;
		}
	}
`;

export default HeaderStyle;
