import { UseCases } from "@aptus/frontend-core";
import { InternalAPI } from "models/internalAPI";
import { RawModuleMessage } from "./models/rawModuleMessage";

export type RawModuleMessageAPI = InternalAPI<RawModuleMessage>;

interface Props {
	API: RawModuleMessageAPI;
}

interface Result {
	rawModuleMessage: RawModuleMessage;
	isLoading: boolean;
	error?: Error;
}

export const useRawModuleMessageUseCases: UseCases<Props, Result> = ({ API }) => (
	{
		error: API.error,
		isLoading: API.isLoading,
		rawModuleMessage: API.data,
	}
);
