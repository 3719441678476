import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { QueryDTO, SchoolyearDTO } from "models/schema";
import { Schoolyear } from "./models/schoolyear";
import { SchoolyearsAPI } from "./useSchoolyearsUseCases";

export type SchoolyearAPIDTO = ApolloAPI<QueryDTO, "schoolyears">;

interface Mapper {
	toSchoolyear: ToInternal<DeepPartial<SchoolyearDTO>, Schoolyear>;
	toAPI: ToAPI<SchoolyearAPIDTO, SchoolyearsAPI>;
}

export class SchoolyearMapper implements Mapper {
	public toSchoolyear: Mapper["toSchoolyear"] = (schoolyear) => ({
		id: schoolyear.id || "",
		title: schoolyear.title || "",
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: SchoolyearAPIDTO["data"]): DeepPartial<SchoolyearDTO[]> => {
			if (data?.schoolyears) {
				return data.schoolyears;
			}

			return [];
		};

		const data = extractDTOs(api?.data);

		return {
			...api,
			isLoading: api?.loading || false,
			data: Array.isArray(data) ? data.map(this.toSchoolyear) : data,
		};
	};
}
