import { UILogic } from "@aptus/frontend-core";
import { useEffect, useState } from "react";
import { SerialPort } from "utils/serialport";
import { MessageSender, SerialPortMessage, SerialPortStatus } from "./models/serialport";

enum Prefix {
	BikeCounter = "h5-",
	Module = "[5002] IMEI: ",
}

export interface SerialPortResult {
	serialPortStatus: SerialPortStatus;
	lastMessage?: SerialPortMessage;
	connect(): void;
	disconnect(): void;
}

const useSerialPort: UILogic<{}, SerialPortResult> = () => {
	const [serialPort, setSerialPort] = useState<SerialPort>();
	const [serialPortStatus, setSerialPortStatus] = useState<SerialPortStatus>(SerialPortStatus.Disconnected);
	const [lastMessage, setLastMessage] = useState<SerialPortMessage>();

	useEffect(() => {
		const triggerDisconnect = async () => {
			if (serialPort) {
				await serialPort.disconnect();
			}
		};

		return () => {
			triggerDisconnect();
		};
	}, []);

	const processBikeCounterMessage = async (message: string) => {
		const [, deviceId] = message.split(Prefix.BikeCounter);

		setLastMessage({
			timestamp: new Date(),
			message,
			senderType: MessageSender.BikeCounter,
			senderId: deviceId,
		});
	};

	const processModuleMessage = async (message: string) => {
		const [, deviceId] = message.split(Prefix.Module);

		setLastMessage({
			timestamp: new Date(),
			message,
			senderType: MessageSender.Module,
			senderId: deviceId,
		});
	};

	const handleMessage = async (message: string) => {
		if (message.indexOf(Prefix.BikeCounter) >= 0) {
			processBikeCounterMessage(message);
		}

		if (message.indexOf(Prefix.Module) >= 0) {
			processModuleMessage(message);
		}
	};

	const disconnect = async () => {
		if (serialPort) {
			await serialPort.disconnect();
		}

		setSerialPort(undefined);
		setSerialPortStatus(SerialPortStatus.Disconnected);
	};

	const connect = async () => {
		await disconnect();
		const newSerialPort = new SerialPort(
			{},
			{
				baudRate: 115200,
			},
			{
				postLimiter: "\r\n",
			},
		);

		newSerialPort.onMessage(handleMessage);

		await newSerialPort.connect();
		setSerialPortStatus(SerialPortStatus.Connected);

		setSerialPort(newSerialPort);
	};

	return {
		serialPortStatus,
		lastMessage,
		connect,
		disconnect,
	};
};

export default useSerialPort;
