import { Domain } from "@aptus/frontend-core";
import { DeviceMapper, DevicesAPIDTO } from "hooks/devices/deviceMapper";
import { useCreateDevice } from "hooks/devices/models/createDevice";
import { useDevicesUseCases } from "hooks/devices/useDevicesUseCases";
import { useUpdateModule } from "hooks/modules/models/updateModule";
import { ModuleMapper, ModulesAPIDTO } from "hooks/modules/moduleMapper";
import { useModulesUseCases } from "hooks/modules/useModulesUseCases";
import { ProjectAPIDTO, ProjectMapper } from "hooks/project/projectMapper";
import { useProjectsUseCases } from "hooks/project/useProjectsUseCases";
import { RawModuleMessageAPIDTO, RawModuleMessageMapper } from "hooks/rawModuleMessage/rawModuleMessageMapper";
import { useRawModuleMessageUseCases } from "hooks/rawModuleMessage/useRawModuleMessagesUseCases";
import { SchoolyearAPIDTO, SchoolyearMapper } from "hooks/schoolyear/schoolyearMapper";
import { useSchoolyearsUseCases } from "hooks/schoolyear/useSchoolyearsUseCases";

export const useDevices: Domain<DevicesAPIDTO, typeof useDevicesUseCases> = (API) => {
	const map = new DeviceMapper();
	const [createDevice] = useCreateDevice();

	return useDevicesUseCases({
		API: map.toAPI(API),
		createMutation: map.toCreateMutation(createDevice),
	});
};

export const useModules: Domain<ModulesAPIDTO, typeof useModulesUseCases> = (API) => {
	const map = new ModuleMapper();
	const [updateModule] = useUpdateModule();

	return useModulesUseCases({
		API: map.toAPI(API),
		updateMutation: map.toUpdateMutation(updateModule),
	});
};

export const useProjects: Domain<ProjectAPIDTO, typeof useProjectsUseCases> = (API) => {
	const map = new ProjectMapper();

	return useProjectsUseCases({
		API: map.toAPI(API),
	});
};

export const useSchoolyears: Domain<SchoolyearAPIDTO, typeof useSchoolyearsUseCases> = (API) => {
	const map = new SchoolyearMapper();

	return useSchoolyearsUseCases({
		API: map.toAPI(API),
	});
};

export const useRawModuleMessageCreated: Domain<RawModuleMessageAPIDTO, typeof useRawModuleMessageUseCases> = (API) => {
	const map = new RawModuleMessageMapper();

	return useRawModuleMessageUseCases({
		API: map.toAPI(API),
	});
};
