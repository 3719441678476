/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { UILayout } from "@aptus/frontend-core";
import { Portal } from "components/portal/Portal";
import dayjs from "dayjs";
import { useModules } from "domains";
import { Location, useGeolocation } from "hooks/geolocation/useGeolocation";
import { useMemo, useState } from "react";
import { ModuleScreenStyle } from "./ModuleScreenStyle";
import { useGetModules } from "./models/getModules";

interface Props {
	deviceId: string;
	schoolyearId: string;
	goToNextStep: (moduleId: string, location: Location, currentReference?: string) => void;
}

export const ModuleScreen: UILayout<Props> = ({
	deviceId,
	schoolyearId,
	goToNextStep,
}) => {
	const [selectedModule, setSelectedModule] = useState<string | undefined>();
	const [search, setSearch] = useState<string | undefined>();

	const { modules } = useModules(useGetModules({ variables: { filter: { schoolyear: { equals: schoolyearId }, softDeleted: { equals: false } } } }));
	const { location } = useGeolocation({});

	const filteredResult = useMemo(() => {
		if (search) {
			return modules.filter((module) => module.reference.toLowerCase().includes(search.toLowerCase()) || module.title.toLowerCase().includes(search.toLowerCase()));
		}
		return modules;
	}, [search, modules]);

	const onClickSelect = async () => {
		if (!selectedModule || !location || !location.lat || !location.lng || !deviceId) {
			return;
		}

		const selectedModuleEntity = modules.find((module) => module.id === selectedModule);
		goToNextStep(selectedModule, location, selectedModuleEntity?.reference);
	};

	const getLastGpsUpdateDate = () => {
		if (!location) {
			return null;
		}

		if (!location.timestamp) {
			return null;
		}

		return new Date(location.timestamp).toISOString();
	};

	const getLocationClassName = () => {
		if (location && location.accuracy) {
			if (location.accuracy <= 5) {
				return "good";
			}

			if (location.accuracy > 5 && location.accuracy < 10) {
				return "medium";
			}

			if (location.accuracy > 10) {
				return "bad";
			}
		}

		return "";
	};

	const getButtonData = () => {
		if (!selectedModule) {
			return {
				text: "Please select a module",
				disabled: true,
			};
		}

		if (!location || !location.accuracy) {
			return {
				text: "Waiting for GPS",
				disabled: true,
			};
		}

		if (location && (location.accuracy > 10)) {
			return {
				text: "Waiting for GPS",
				disabled: true,
			};
		}

		return {
			disabled: false,
			text: "Next",
		};
	};

	return (
		<ModuleScreenStyle>
			<h2>Modules</h2>
			<p>
				{`Last GPS update: ${getLastGpsUpdateDate() ? dayjs(getLastGpsUpdateDate()).format("DD/MM/YYYY HH:mm:ss") : "Never"}`}
			</p>
			<p className={getLocationClassName()}>
				{`Last GPS accuracy: ${location && location.accuracy ? Math.round(location.accuracy) : "N/A"}`}
			</p>
			<p>Select a module to link device to</p>
			<input className="textInput" type="search" placeholder="Type here to search" onChange={(e) => setSearch(e.currentTarget.value)} />
			<div className="moduleList">
				{filteredResult.map((module) => (
					<div role="button" onClick={() => setSelectedModule(module.id)} className={`moduleRecord ${selectedModule === module.id ? "selected" : ""} ${module.reference === "JOBSTUDENT TODO" ? "jobstudent" : ""} ${(module.reference || "").toLowerCase() === "robin" ? "robin" : ""}`} key={module.id}>
						{module.title}
						{" - "}
						{module.reference}
						<p className="deviceId">{module.deviceId}</p>
					</div>
				))}
			</div>
			<Portal name="actionbar">
				<button disabled={getButtonData()?.disabled} type="button" className="primary" onClick={onClickSelect}>{getButtonData()?.text}</button>
			</Portal>
		</ModuleScreenStyle>
	);
};
