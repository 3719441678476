import { UILayout } from "@aptus/frontend-core";
import { Portal } from "components/portal/Portal";
import dayjs from "dayjs";
import { useRawModuleMessageCreated } from "domains";
import { RawModuleMessage } from "hooks/rawModuleMessage/models/rawModuleMessage";
import { useState } from "react";
import { useSubscribeRawModuleMessageCreated } from "../moduleScreen/models/getRawModuleMessages";
import { NFCDeviceSelectionScreenStyle } from "./NFCDeviceSelectionScreenStyle";

interface Props {
	tagId: string;
	goToNextStep: (deviceId: string, nfcLastRawModuleMessage: RawModuleMessage | undefined) => void;
}

export const NFCDeviceSelectionScreen: UILayout<Props> = ({
	tagId,
	goToNextStep,
}) => {
	const [lastRawModuleMessage, setLastRawModuleMessage] = useState<RawModuleMessage | undefined>();
	const { rawModuleMessage } = useRawModuleMessageCreated(useSubscribeRawModuleMessageCreated({ variables: { nfcId: tagId } }) as any);

	if (
		rawModuleMessage
		&& rawModuleMessage.deviceId
		&& (!lastRawModuleMessage
			|| (lastRawModuleMessage.createdAt !== rawModuleMessage.createdAt)
			|| (lastRawModuleMessage.deviceId !== rawModuleMessage.deviceId)
			|| (lastRawModuleMessage.data !== rawModuleMessage.data)
			|| (lastRawModuleMessage.receivedAt !== rawModuleMessage.receivedAt)
		)
		&& (Math.floor((new Date().getTime() - new Date(rawModuleMessage.createdAt).getTime()) / 1000) <= 300)
	) {
		setLastRawModuleMessage(rawModuleMessage);
	}

	const onClickNext = () => {
		if (lastRawModuleMessage && lastRawModuleMessage.deviceId) {
			goToNextStep(lastRawModuleMessage.deviceId || "", lastRawModuleMessage);
		}
	};

	const waitingForDataView = () => (
		<div className="waitingForDataView">
			<h2>Waiting For Data</h2>
			<p>Please scan your NFC tag on the module</p>
			<div className="loading" />
		</div>
	);

	const getBatteryVoltage = () => {
		if (lastRawModuleMessage && lastRawModuleMessage.data && lastRawModuleMessage.data.battery) {
			return lastRawModuleMessage.data.battery / 1000;
		}

		return null;
	};

	const isBatteryGood = () => {
		const voltage = getBatteryVoltage();

		return voltage === null || voltage >= 4;
	};

	const getBatteryStatusText = () => {
		if (isBatteryGood()) {
			return "Good";
		}

		return "Take Different Module!";
	};

	const getBatteryStatusClassName = () => {
		if (isBatteryGood()) {
			return "good";
		}

		return "bad";
	};

	const getNextButtonData = () => {
		if (!isBatteryGood()) {
			return {
				disabled: false,
				text: "Battery Too Low! Proceed with caution",
			};
		}

		return {
			disabled: false,
			text: "Next",
		};
	};

	const dataReceivedView = () => (
		<>
			<h2>Data Received</h2>
			<div className="infoSection">
				<p className="lastUpdate">{`Received on server at: ${dayjs(lastRawModuleMessage?.receivedAt).format("DD/MM/YYYY HH:mm:ss")}`}</p>
				<p className="lastUpdate">{`Tagged at: ${dayjs(lastRawModuleMessage?.createdAt).format("DD/MM/YYYY HH:mm:ss")}`}</p>

				<div className="deviceData">
					<div>
						<h3>Device ID</h3>
						<p>{lastRawModuleMessage?.deviceId || "Waiting For Device ID"}</p>
					</div>
					<div className={getBatteryStatusClassName()} hidden={!getBatteryVoltage()}>
						<h3>Battery Status</h3>
						<p>
							{`${getBatteryStatusText()} (${getBatteryVoltage()}V)`}
						</p>
					</div>
					<div>
						<h3>Payload</h3>
						<p>{JSON.stringify(lastRawModuleMessage?.data) || "Waiting For Device"}</p>
					</div>
				</div>
			</div>
			<Portal name="actionbar">
				<button
					className="primary"
					type="button"
					onClick={onClickNext}
					disabled={getNextButtonData().disabled}
				>
					{getNextButtonData().text}
				</button>
			</Portal>
		</>
	);

	return (
		<NFCDeviceSelectionScreenStyle>
			{
				!lastRawModuleMessage ? waitingForDataView() : dataReceivedView()
			}
		</NFCDeviceSelectionScreenStyle>
	);
};
