export enum MessageSender {
	BikeCounter = "BIKE_COUNTER",
	Module = "MODULE",
}

export interface SerialPortMessage {
	timestamp: Date;
	senderType: MessageSender;
	senderId: string;
	message?: string;
}

export enum SerialPortStatus {
	Connected = "CONNECTED",
	Disconnected = "DISCONNECTED",
}
