import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { ProjectDTO, QueryDTO } from "models/schema";
import { Project } from "./models/project";
import { ProjectsAPI } from "./useProjectsUseCases";

export type ProjectAPIDTO = ApolloAPI<QueryDTO, "projects">;

interface Mapper {
	toProject: ToInternal<DeepPartial<ProjectDTO>, Project>;
	toAPI: ToAPI<ProjectAPIDTO, ProjectsAPI>;
}

export class ProjectMapper implements Mapper {
	public toProject: Mapper["toProject"] = (project) => ({
		id: project.id || "",
		title: project.title || "",
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: ProjectAPIDTO["data"]): DeepPartial<ProjectDTO[]> => {
			if (data?.projects) {
				return data.projects;
			}

			return [];
		};

		const data = extractDTOs(api?.data);

		return {
			...api,
			isLoading: api?.loading || false,
			data: Array.isArray(data) ? data.map(this.toProject) : data,
		};
	};
}
