import {
	ApolloClient,
	HttpLink,
	InMemoryCache,
	split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { event } from "event";
import { createClient } from "graphql-ws";
import isJwtTokenExpired from "jwt-check-expiry";

const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem("token");

	if (token) {
		if (isJwtTokenExpired(token)) {
			event.emit("tokenExpired");
			localStorage.removeItem("token");
			window.location.replace("/");
		}
	}

	return {
		headers: {
			...headers,
			authorization: token && !isJwtTokenExpired(token) ? `Bearer ${token}` : "",
		},
	};
});

const httpLink = new HttpLink({
	uri: window.appEnvironment.API_URL,
	fetch: (...args) => fetch(...args),
});

const wsLink = new GraphQLWsLink(
	createClient({
		url: window.appEnvironment.WEBSOCKETS_URL,
		connectionParams: () => {
			const token = localStorage.getItem("token");

			return {
				authToken: token ? `Bearer ${token}` : "",
			};
		},
	}),
);

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === "OperationDefinition" && definition.operation === "subscription"
		);
	},
	wsLink,
	authLink.concat(httpLink),
);

export default new ApolloClient({
	cache,
	link: splitLink,
	// name: "high-five-provision-app",
	// version: "0.1.0",
});
