import { UILayout } from "@aptus/frontend-core";
import { Portal } from "components/portal/Portal";
import { useEffect, useState } from "react";

interface Props {
	goToNextStep: (tagId: string) => void;
}

export const NFCSelectionScreen: UILayout<Props> = ({
	goToNextStep,
}) => {
	const [tagId, setTagId] = useState<string | undefined>();

	useEffect(() => {
		const savedTagId = localStorage.getItem("tagId");

		if (savedTagId) {
			setTagId(savedTagId);
		}
	}, []);

	const onClickNext = () => {
		if (tagId && tagId.length) {
			goToNextStep(tagId.toUpperCase());
		}
	};

	return (
		<div>
			<h2>Get started by entering your tag ID</h2>
			<Portal name="actionbar">
				<input type="text" className="textInput" placeholder="Tag ID" defaultValue={tagId} onChange={(e) => { setTagId(e.currentTarget.value); localStorage.setItem("tagId", e.currentTarget.value); }} />
				<button
					className="primary"
					type="button"
					onClick={onClickNext}
				>
					Next
				</button>
			</Portal>
		</div>
	);
};
