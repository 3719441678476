/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateDeviceVariablesDTO = Types.Exact<{
  data: Types.DeviceCreateInputDTO;
}>;


export type CreateDeviceDTO = (
  { __typename?: 'Mutation' }
  & { createOneDevice: (
    { __typename?: 'Device' }
    & Pick<Types.DeviceDTO, 'id' | 'type'>
  ) }
);


export const CreateDeviceDocumentDTO = gql`
    mutation createDevice($data: DeviceCreateInput!) {
  createOneDevice(data: $data) {
    id
    type
  }
}
    `;
export type CreateDeviceMutationFnDTO = Apollo.MutationFunction<CreateDeviceDTO, CreateDeviceVariablesDTO>;

/**
 * __useCreateDevice__
 *
 * To run a mutation, you first call `useCreateDevice` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDevice` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDevice, { data, loading, error }] = useCreateDevice({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDevice(baseOptions?: Apollo.MutationHookOptions<CreateDeviceDTO, CreateDeviceVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeviceDTO, CreateDeviceVariablesDTO>(CreateDeviceDocumentDTO, options);
      }
export type CreateDeviceHookResult = ReturnType<typeof useCreateDevice>;
export type CreateDeviceMutationResultDTO = Apollo.MutationResult<CreateDeviceDTO>;
export type CreateDeviceMutationOptionsDTO = Apollo.BaseMutationOptions<CreateDeviceDTO, CreateDeviceVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    