import { PageComponent } from "@aptus/frontend-core";

import PageStyle from "./PageStyle";

const Page: PageComponent = ({
	children,
}) => (
	<PageStyle>
		<main className="container">
			{children}
		</main>
	</PageStyle>
);

export default Page;
