import { UILayout } from "@aptus/frontend-core";
import { Portal } from "components/portal/Portal";
import { useModules } from "domains";
import { Location } from "hooks/geolocation/useGeolocation";
import { useGetModules } from "../moduleScreen/models/getModules";
import SuccessScreenStyle from "./successScreenStyle";

interface Props {
	moduleId?: string;
	location?: Location;

	startOver: () => void;
}

export const SuccessScreen: UILayout<Props> = ({
	moduleId,
	location,
	startOver,
}) => {
	const { modules, isLoading } = useModules(useGetModules({ variables: { filter: { id: { equals: moduleId } } }, skip: !moduleId }));

	if (isLoading) {
		return (
			<SuccessScreenStyle>
				<div className="loading" />
			</SuccessScreenStyle>
		);
	}

	if (!modules || !modules.length) {
		return (
			<SuccessScreenStyle>
				<h1>Something went wrong</h1>
				<h2>Please try again</h2>

				<Portal name="actionbar">
					<button type="button" className="primary" onClick={startOver}>Start over</button>
				</Portal>
			</SuccessScreenStyle>
		);
	}

	const module = modules[0];
	const { title, deviceId, reference } = module;

	return (
		<SuccessScreenStyle>
			<h1>Success!</h1>
			<h2>You have successfully provisioned the device!</h2>

			<div className="summary">
				<p>Here&apos;s a quick summary:</p>
				<table>
					<tbody>
						<tr>
							<td>Module</td>
							<td>{title}</td>
						</tr>
						<tr>
							<td>Device ID</td>
							<td>{deviceId}</td>
						</tr>
						<tr>
							<td>Location</td>
							<td>
								{`LNG: ${Math.round((location?.lng || 0) * 100) / 100} LAT: ${Math.round((location?.lat || 0) * 100) / 100} ACC: ${Math.round((location?.accuracy || 0) * 100) / 100}`}
							</td>
						</tr>
						<tr>
							<td>Reference</td>
							<td>{reference}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<Portal name="actionbar">
				<button type="button" className="primary" onClick={startOver}>Start over</button>
			</Portal>
		</SuccessScreenStyle>
	);
};
