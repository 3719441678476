/* eslint-disable */
import * as Types from '../../../../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetProjectsVariablesDTO = Types.Exact<{ [key: string]: never; }>;


export type GetProjectsDTO = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Types.ProjectDTO, 'id' | 'title'>
  )> }
);


export const GetProjectsDocumentDTO = gql`
    query getProjects {
  projects(sort: [{field: "title", order: ASC}]) {
    id
    title
  }
}
    `;

/**
 * __useGetProjects__
 *
 * To run a query within a React component, call `useGetProjects` and pass it any options that fit your needs.
 * When your component renders, `useGetProjects` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjects({
 *   variables: {
 *   },
 * });
 */
export function useGetProjects(baseOptions?: Apollo.QueryHookOptions<GetProjectsDTO, GetProjectsVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsDTO, GetProjectsVariablesDTO>(GetProjectsDocumentDTO, options);
      }
export function useGetProjectsLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsDTO, GetProjectsVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsDTO, GetProjectsVariablesDTO>(GetProjectsDocumentDTO, options);
        }
export type GetProjectsHookResult = ReturnType<typeof useGetProjects>;
export type GetProjectsLazyQueryDTOHookResult = ReturnType<typeof useGetProjectsLazyQueryDTO>;
export type GetProjectsQueryResultDTO = Apollo.QueryResult<GetProjectsDTO, GetProjectsVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    