import wiredProvisionIcon from "assets/icons/wired-provision-white.svg";
import wirelessProvisionIcon from "assets/icons/wireless-provision-white.svg";
import { createGlobalStyle } from "styled-components";
import Colors from "./colors";

/**
 * Default look for all the standard HTML elements.
 */

const GlobalStyle = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html {
		font-size: 16px; /* 1rem = 2 x 8pt grid units. */
		font-family: 'Source Sans Pro', sans-serif;
	}

	select {
		width: 100%;
		height: 30px;
		font-family: 'Source Sans Pro', sans-serif;
	}

	.actionbar {
		position: fixed;
		bottom: 0;
		width: 100vw;
		padding: 10px;

		button {
			margin-top: 10px;
			font-size: 1.5rem;

			background-repeat: no-repeat;
			background-position-x: 25px;
			background-size: contain;
			background-position: left 20px center;

			&.wired-provision {
				background-image: url("${wiredProvisionIcon}");
			}

			&.wireless-provision {
				background-image: url("${wirelessProvisionIcon}");
			}
		}
	}
	
	.errorBox {
		background-color: ${Colors.errorLight};
		border: ${Colors.error} 1px solid;
		border-radius: 5px;
	}

	button, input[type=submit] {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: ${Colors.black};
		background-color: ${Colors.grey};
		cursor: pointer;
		border-radius: 4px;
		height: 48px;
		padding: 20px;
		font-family: 'Source Sans Pro', sans-serif;
		border: none;
		font-size: 16px;
	
		&:hover {
			background-color: ${Colors.darkGrey};
		}

		&.primary {
			background-color: ${Colors.highFiveBlue};
			color: ${Colors.grey};
		}

		&.primary:disabled {
			background-color: ${Colors.darkGrey};
		}
	}

	.textInput {
		width: 100%;
		border: 1px solid ${Colors.darkGrey};
		padding: 10px;
		margin: 10px 0;
	}

	.loading {
		display: inline-block;
		width: 80px;
		height: 80px;
		margin-top: 10px;
	}

	.loading:after {
		content: " ";
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid #000;
		border-color: #000 transparent #000 transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
	
	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

export default GlobalStyle;
