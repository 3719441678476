import { PageComponent } from "@aptus/frontend-core";
import { useEffect, useState } from "react";
import { LoginPageStyle } from "./LoginPageStyle";
import { useLogin } from "./models/login";

const LoginPage: PageComponent = () => {
	const [email, setEmail] = useState<string>();
	const [password, setPassword] = useState<string>();

	const [mutation] = useLogin();
	const [hasError, setHasErrors] = useState<boolean>(false);

	useEffect(() => {
		localStorage.removeItem("token");
	}, []);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setHasErrors(false);

		try {
			const { data, errors } = await mutation({
				variables: {
					email: email || "",
					password: password || "",
				},
			});

			if (errors || !data || !data.login || !data.login.token) {
				setHasErrors(true);
				return;
			}

			localStorage.setItem("token", data.login.token);
			window.location.replace(localStorage.getItem("redirectRoute") || "/");
		} catch (error) {
			setHasErrors(true);
		}
	};

	return (
		<LoginPageStyle>
			<form onSubmit={handleSubmit}>
				<p className="error" hidden={!hasError}>Credentials resulted in error, please check data and try again</p>
				<label htmlFor="email">
					Email
					<input type="email" id="email" className="textInput" onChange={(e) => (setEmail(e.target.value))} required />
				</label>

				<label htmlFor="password">
					Password
					<input type="password" id="password" className="textInput" onChange={(e) => (setPassword(e.target.value))} required />
				</label>
				<input name="login" type="submit" className="primary" value="Inloggen" />
			</form>
		</LoginPageStyle>
	);
};

export default LoginPage;
