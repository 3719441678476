import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import { event } from "event";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Device } from "./models/device";
import { CreateDeviceInput } from "./models/deviceInput";

export type DevicesAPI = InternalAPI<Device[] | number>;

export type CreateDeviceMutation = Mutation<CreateDeviceInput, Device>;

interface Props {
	API: DevicesAPI;
	createMutation: CreateDeviceMutation;
}

interface Result {
	count: number;
	isLoading: boolean;
	error?: Error;
	create: (input: CreateDeviceInput) => Promise<void>;
}

export const useDevicesUseCases: UseCases<Props, Result> = ({ API, createMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);

	const create: Result["create"] = async (input) => {
		setIsLoading(true);
		try {
			await createMutation(input);
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	return {
		error: API.error,
		isLoading: API.isLoading || isLoading,
		count: !Array.isArray(API.data) ? API.data : 0,
		create,
	};
};
