/* eslint-disable */
import * as Types from '../../../../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SubscribeRawModuleMessageCreatedVariablesDTO = Types.Exact<{
  nfcId: Types.Scalars['String'];
}>;


export type SubscribeRawModuleMessageCreatedDTO = (
  { __typename?: 'Subscription' }
  & { rawModuleMessageCreated?: Types.Maybe<(
    { __typename?: 'RawModuleMessage' }
    & Pick<Types.RawModuleMessageDTO, 'id' | 'deviceId' | 'createdAt' | 'receivedAt' | 'data'>
  )> }
);


export const SubscribeRawModuleMessageCreatedDocumentDTO = gql`
    subscription subscribeRawModuleMessageCreated($nfcId: String!) {
  rawModuleMessageCreated(filter: {data: {nfcId: {equals: $nfcId}}}) {
    id
    deviceId
    createdAt
    receivedAt
    data
  }
}
    `;

/**
 * __useSubscribeRawModuleMessageCreated__
 *
 * To run a query within a React component, call `useSubscribeRawModuleMessageCreated` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeRawModuleMessageCreated` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeRawModuleMessageCreated({
 *   variables: {
 *      nfcId: // value for 'nfcId'
 *   },
 * });
 */
export function useSubscribeRawModuleMessageCreated(baseOptions: Apollo.SubscriptionHookOptions<SubscribeRawModuleMessageCreatedDTO, SubscribeRawModuleMessageCreatedVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeRawModuleMessageCreatedDTO, SubscribeRawModuleMessageCreatedVariablesDTO>(SubscribeRawModuleMessageCreatedDocumentDTO, options);
      }
export type SubscribeRawModuleMessageCreatedHookResult = ReturnType<typeof useSubscribeRawModuleMessageCreated>;
export type SubscribeRawModuleMessageCreatedSubscriptionResultDTO = Apollo.SubscriptionResult<SubscribeRawModuleMessageCreatedDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    