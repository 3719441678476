/* eslint-disable */
import * as Types from '../../../../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetModulesVariablesDTO = Types.Exact<{
  filter?: Types.Maybe<Types.ModuleFilterDTO>;
}>;


export type GetModulesDTO = (
  { __typename?: 'Query' }
  & { modules: Array<(
    { __typename?: 'Module' }
    & Pick<Types.ModuleDTO, 'id' | 'title' | 'deviceId' | 'reference'>
  )> }
);


export const GetModulesDocumentDTO = gql`
    query getModules($filter: ModuleFilter) {
  modules(filter: $filter) {
    id
    title
    deviceId
    reference
  }
}
    `;

/**
 * __useGetModules__
 *
 * To run a query within a React component, call `useGetModules` and pass it any options that fit your needs.
 * When your component renders, `useGetModules` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModules({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetModules(baseOptions?: Apollo.QueryHookOptions<GetModulesDTO, GetModulesVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModulesDTO, GetModulesVariablesDTO>(GetModulesDocumentDTO, options);
      }
export function useGetModulesLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetModulesDTO, GetModulesVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModulesDTO, GetModulesVariablesDTO>(GetModulesDocumentDTO, options);
        }
export type GetModulesHookResult = ReturnType<typeof useGetModules>;
export type GetModulesLazyQueryDTOHookResult = ReturnType<typeof useGetModulesLazyQueryDTO>;
export type GetModulesQueryResultDTO = Apollo.QueryResult<GetModulesDTO, GetModulesVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    