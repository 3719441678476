import styled from "styled-components";

export const LoginPageStyle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	height: 100vh;
    padding: 20px;

	.primary {
		padding: 0px;
	}

	.error {
		color: red;
		margin-bottom: 10px;
	}
`;
