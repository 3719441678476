/* eslint-disable jsx-a11y/click-events-have-key-events */
import { UILayout } from "@aptus/frontend-core";
import { useProjects } from "domains";
import { useGetProjects } from "./models/getProjects";
import { ProjectScreenStyle } from "./ProjectScreenStyle";

interface Props {
	goToNextStep: (projectId: string) => void;
}

export const ProjectScreen: UILayout<Props> = ({
	goToNextStep,
}) => {
	const { projects } = useProjects(useGetProjects({ variables: {} }));

	const onClickProject = (projectId: string) => {
		goToNextStep(projectId);
	};

	return (
		<ProjectScreenStyle>
			<h1>PROJECTS</h1>
			{projects.map((project, index) => (
				<div className="project" onClick={() => onClickProject(project.id)} key={project.title} role="button" tabIndex={index}>
					<h2>{project.title}</h2>
				</div>
			))}
		</ProjectScreenStyle>
	);
};
