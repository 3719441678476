import {
	DeepPartial, IOMap, ToAPI, ToInternal,
} from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { MessageSender } from "hooks/serialport/models/serialport";
import { DeviceDTO, QueryDTO, DeviceTypeDTO } from "models/schema";
import { CreateDeviceMutationFnDTO } from "./models/createDevice";
import { Device } from "./models/device";
import { CreateDeviceMutation, DevicesAPI } from "./useDevicesUseCases";

export type DevicesAPIDTO = ApolloAPI<QueryDTO, "devices" | "devicesCount">;

interface Mapper {
	toAPI: ToAPI<DevicesAPIDTO, DevicesAPI>;
	toCreateMutation: ToInternal<CreateDeviceMutationFnDTO, CreateDeviceMutation>;
	toDevice: ToInternal<DeepPartial<DeviceDTO>, Device>;
}

export class DeviceMapper implements Mapper {
	public toDevice: Mapper["toDevice"] = (device) => ({
		id: device.id || "",
		type: device.type || DeviceTypeDTO.HttpDTO,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: DevicesAPIDTO["data"]): DeepPartial<DeviceDTO[] | number> => {
			if (data?.devices) {
				return data.devices;
			}

			if (data?.devicesCount) {
				return data.devicesCount || 0;
			}

			return [];
		};

		const data = extractDTOs(api?.data);

		return {
			...api,
			isLoading: api?.loading || false,
			count: api?.data?.devicesCount,
			data: Array.isArray(data) ? data.map(this.toDevice) : data,
		};
	};

	public toCreateMutation: Mapper["toCreateMutation"] = (mutation) => {
		const map: IOMap<CreateDeviceMutationFnDTO, CreateDeviceMutation> = {
			toInput: (input) => ({
				variables: {
					data: {
						id: input.id,
						type: input.type === MessageSender.Module ? DeviceTypeDTO.HttpDTO : DeviceTypeDTO.IotcDTO,
					},
				},
			}),
			toOutput: (output) => ((output.data?.createOneDevice ? this.toDevice(output.data.createOneDevice) : undefined)),
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}
