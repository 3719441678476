import { ApolloError } from "@apollo/client";
import { Mutation, UseCases } from "@aptus/frontend-core";
import { event } from "event";
import { InternalAPI } from "models/internalAPI";
import { useState } from "react";
import { Module } from "./models/module";
import { UpdateModuleInput } from "./models/moduleInput";

export type ModulesAPI = InternalAPI<Module[]>;
export type UpdateModuleMutation = Mutation<UpdateModuleInput, Module>;

interface Props {
	API: ModulesAPI;
	updateMutation: UpdateModuleMutation;

}

interface Result {
	modules: Module[];
	isLoading: boolean;
	error?: Error;
	update: (input: UpdateModuleInput) => Promise<void>;

}

export const useModulesUseCases: UseCases<Props, Result> = ({ API, updateMutation }) => {
	const [isLoading, setIsLoading] = useState<Result["isLoading"]>(false);

	const update: Result["update"] = async (input) => {
		setIsLoading(true);
		try {
			await updateMutation(input);
		} catch (error) {
			if (error instanceof ApolloError) {
				event.emit("mutationFailed", error);
			}
		}
		setIsLoading(false);
	};

	return {
		error: API.error,
		isLoading: API.isLoading || isLoading,
		modules: API.data,
		update,
	};
};
