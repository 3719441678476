const Colors = {
	black: "#2d465b",
	grey: "#ebf1f4",
	darkGrey: "#d2e0e6",
	green: "#36a849",
	highFiveBlue: "#256be9",
	error: "#ff4d4d",
	errorLight: "#eb8686",
};

export default Colors;
