import { EventEmitter } from "events";
import MessageBuffer from "./messagebuffer";

export interface SerialPortMessageConfiguration {
	preLimiter?: string;
	postLimiter?: string;
}

export class SerialPort {
	private port: globalThis.SerialPort | undefined;

	private reader: ReadableStreamDefaultReader<Uint8Array> | undefined;

	private readonly messageBuffer: MessageBuffer;

	private readonly externalEventEmitter = new EventEmitter();

	private connected = false;

	constructor(
		private readonly requestOptions: SerialPortRequestOptions = {},
		private readonly connectOptions: SerialOptions,
		private readonly messageConfiguration: SerialPortMessageConfiguration,
	) {
		if (!navigator || !navigator.serial) {
			throw new Error("Serial port not supported by browser");
		}

		this.messageBuffer = new MessageBuffer({
			postLimiter: this.messageConfiguration.postLimiter,
			preLimiter: this.messageConfiguration.preLimiter,
		});
	}

	private handleConnect(): void {
		this.connected = true;
		this.startReader();
		this.externalEventEmitter.emit("connect");
	}

	private handleDisconnect(): void {
		this.connected = false;
		this.externalEventEmitter.emit("disconnect");
	}

	private async startReader(): Promise<void> {
		if (!this.port || !this.port.readable) {
			throw new Error("Port not open");
		}

		while (this.port.readable && this.connected) {
			this.reader = this.port.readable.getReader();
			try {
				// eslint-disable-next-line no-await-in-loop
				const { value, done } = await this.reader.read();
				if (done) {
					break;
				}

				if (value) {
					this.messageBuffer.push(Buffer.from(value as any, "hex").toString());

					while (this.messageBuffer.hasFullMessages()) {
						const message = this.messageBuffer.getFirstMessage();
						this.externalEventEmitter.emit("message", message);
					}
				}
			} catch (error) {
				// Handle |error|...
			} finally {
				this.reader.releaseLock();
			}
		}
	}

	public async connect(): Promise<void> {
		this.port = await navigator.serial.requestPort(this.requestOptions);

		if (!this.port) {
			throw new Error("Port intialization failed");
		}

		this.port.addEventListener("connect", () => {
			this.handleConnect();
		});

		this.port.addEventListener("disconnect", () => {
			this.handleDisconnect();
		});

		await this.port.open(this.connectOptions);
		await this.handleConnect();
	}

	public async disconnect(): Promise<void> {
		if (this.reader) {
			await this.reader.cancel();
			this.reader.releaseLock();
		}

		if (this.port) {
			await this.port.close();
		}

		this.handleDisconnect();
	}

	public onMessage(callback: (message: string) => void): void {
		this.externalEventEmitter.on("message", callback);
	}
}
