import { UILayout } from "@aptus/frontend-core";
import { Portal } from "components/portal/Portal";
import dayjs from "dayjs";
import { useDevices } from "domains";
import { RawModuleMessage } from "hooks/rawModuleMessage/models/rawModuleMessage";
import { MessageSender } from "hooks/serialport/models/serialport";
import { useState } from "react";
import { DeviceScreenStyle } from "./DeviceScreenStyle";
import { useCountDevices } from "./models/countDevices";

interface Props {
	deviceId: string;
	lastRawModuleMessage: RawModuleMessage | undefined;
	goToNextStep: (deviceId: string) => void;
}

export const DeviceScreen: UILayout<Props> = ({
	deviceId,
	lastRawModuleMessage,
	goToNextStep,
}) => {
	const [moduleType, setModuleType] = useState<MessageSender>(MessageSender.Module);

	const {
		count, create, isLoading, error,
	} = useDevices(useCountDevices({ variables: { filter: { id: { equals: deviceId } } }, skip: !deviceId }));

	if (!isLoading && count > 0) {
		goToNextStep(deviceId || "");
	}

	const onClickCreate = async () => {
		await create({
			id: deviceId || "",
			type: moduleType,
		});
		goToNextStep(deviceId || "");
	};

	return (
		<DeviceScreenStyle>
			<h2>Device Overview</h2>
			<div className="infoSection">
				<p className="lastUpdate">{`Last received on server: ${dayjs(lastRawModuleMessage?.receivedAt).format("DD/MM/YYYY HH:mm:ss")}`}</p>
				<p className="lastUpdate">{`Device created at: ${dayjs(lastRawModuleMessage?.createdAt).format("DD/MM/YYYY HH:mm:ss")}`}</p>

				<div className="deviceData">
					<div>
						<h3>Device ID</h3>
						<p>{lastRawModuleMessage?.deviceId || "Waiting For Device ID"}</p>
					</div>
					<div>
						<h3>Payload</h3>
						<p>{JSON.stringify(lastRawModuleMessage?.data) || "Waiting For Device"}</p>
					</div>
				</div>
			</div>
			{
				!isLoading && count === 0 ? (
					<div className="infoSection">
						<h3>Device Type</h3>
						<select onChange={(e) => (setModuleType(e.target.value as MessageSender))}>
							<option value={MessageSender.Module}>Module</option>
							<option value={MessageSender.BikeCounter}>Bike Counter</option>
						</select>
					</div>
				) : null
			}
			{
				error
					? (
						<div className="errorBox">
							<p>
								Something went wrong during the creation of the device:
								{error}
							</p>
						</div>
					)
					: null
			}
			<Portal name="actionbar">
				{!isLoading && count === 0
					? <button className="primary" type="button" disabled={isLoading} onClick={onClickCreate}>Create and continue</button>
					: <button className="primary" type="button" disabled={isLoading} onClick={() => { goToNextStep(deviceId || ""); }}>Continue</button>}
			</Portal>
		</DeviceScreenStyle>
	);
};
