import { UIComponent } from "@aptus/frontend-core";
import logo from "assets/logo.png";
import HeaderStyle from "./headerStyle";

interface Props {
	goHome?: () => void;
	logout: () => void;
}

const Header: UIComponent<Props> = ({ goHome, logout }) => (
	<HeaderStyle>
		<div className="content" onClick={goHome} aria-hidden="true">
			<img style={{ height: "100%" }} src={logo} alt="logo" />
		</div>
		<div className="buttons">
			<button type="button" onClick={logout}>Logout</button>
			<button type="button" onClick={goHome}>Start over</button>
		</div>
	</HeaderStyle>
);

export default Header;
