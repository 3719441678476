/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateModuleVariablesDTO = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.ModuleUpdateInputDTO;
}>;


export type UpdateModuleDTO = (
  { __typename?: 'Mutation' }
  & { updateOneModule: (
    { __typename?: 'Module' }
    & Pick<Types.ModuleDTO, 'id' | 'deviceId' | 'title' | 'reference'>
  ) }
);


export const UpdateModuleDocumentDTO = gql`
    mutation updateModule($id: String!, $data: ModuleUpdateInput!) {
  updateOneModule(id: $id, data: $data) {
    id
    deviceId
    title
    reference
  }
}
    `;
export type UpdateModuleMutationFnDTO = Apollo.MutationFunction<UpdateModuleDTO, UpdateModuleVariablesDTO>;

/**
 * __useUpdateModule__
 *
 * To run a mutation, you first call `useUpdateModule` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModule` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModule, { data, loading, error }] = useUpdateModule({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateModule(baseOptions?: Apollo.MutationHookOptions<UpdateModuleDTO, UpdateModuleVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleDTO, UpdateModuleVariablesDTO>(UpdateModuleDocumentDTO, options);
      }
export type UpdateModuleHookResult = ReturnType<typeof useUpdateModule>;
export type UpdateModuleMutationResultDTO = Apollo.MutationResult<UpdateModuleDTO>;
export type UpdateModuleMutationOptionsDTO = Apollo.BaseMutationOptions<UpdateModuleDTO, UpdateModuleVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    