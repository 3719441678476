import { DeepPartial, ToAPI, ToInternal } from "@aptus/frontend-core";
import { ApolloAPI } from "@aptus/frontend-core-apollo";
import { RawModuleMessageDTO, SubscriptionDTO } from "models/schema";
import { RawModuleMessage } from "./models/rawModuleMessage";
import { RawModuleMessageAPI } from "./useRawModuleMessagesUseCases";

export type RawModuleMessageAPIDTO = ApolloAPI<SubscriptionDTO, "rawModuleMessageCreated">;

interface Mapper {
	toRawModuleMessage: ToInternal<DeepPartial<RawModuleMessageDTO>, RawModuleMessage>;
	toAPI: ToAPI<RawModuleMessageAPIDTO, RawModuleMessageAPI>;
}

export class RawModuleMessageMapper implements Mapper {
	public toRawModuleMessage: Mapper["toRawModuleMessage"] = (rawModuleMessage) => ({
		id: rawModuleMessage.id || "",
		deviceId: rawModuleMessage.deviceId || "",
		createdAt: rawModuleMessage.createdAt || "",
		receivedAt: rawModuleMessage.receivedAt || "",
		data: rawModuleMessage.data,
	});

	public toAPI: Mapper["toAPI"] = (api) => {
		const extractDTOs = (data: RawModuleMessageAPIDTO["data"]): DeepPartial<RawModuleMessageDTO> => {
			if (data?.rawModuleMessageCreated) {
				return data.rawModuleMessageCreated;
			}

			return {};
		};

		const data = extractDTOs(api?.data);

		return {
			...api,
			isLoading: api?.loading || false,
			data: this.toRawModuleMessage(data),
		};
	};
}
