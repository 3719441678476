import { ApolloProvider } from "@apollo/client";
import { FunctionComponent, StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import client from "./client";
import reportWebVitals from "./reportWebVitals";
import Router from "./Router";
import GlobalStyle from "./style/global";

// Uncomment this and import worker from './mocks/worker' to use the mocked API in the browser

// if (process.env.NODE_ENV === 'development') {
//   worker.start();
// }

const App: FunctionComponent = () => (
	<StrictMode>
		<GlobalStyle />
		<ApolloProvider client={client}>
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		</ApolloProvider>
	</StrictMode>
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
