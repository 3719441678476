import { UILayout } from "@aptus/frontend-core";
import { Portal } from "components/portal/Portal";
import dayjs from "dayjs";
import { RawModuleMessage } from "hooks/rawModuleMessage/models/rawModuleMessage";
import { SerialPortStatus } from "hooks/serialport/models/serialport";
import useSerialPort from "hooks/serialport/useSerialport";
import { useState } from "react";
import { SerialDeviceSelectionScreenStyle } from "./SerialDeviceSelectionScreenStyle";

interface Props {
	goToNextStep: (deviceId: string, lastRawModuleMessage: RawModuleMessage | undefined) => void;
}

export const SerialDeviceSelectionScreen: UILayout<Props> = ({
	goToNextStep,
}) => {
	const [lastRawModuleMessage, setLastRawModuleMessage] = useState<RawModuleMessage | undefined>();
	const {
		lastMessage,
		serialPortStatus,
		connect,
		disconnect,
	} = useSerialPort({});

	if (lastMessage && lastMessage.senderId && lastMessage.senderId !== lastRawModuleMessage?.deviceId) {
		setLastRawModuleMessage({
			deviceId: lastMessage.senderId,
			createdAt: lastMessage.timestamp,
			receivedAt: lastMessage.timestamp,
			id: "MANUAL",
			data: {},
		});
	}

	const onClickConnect = () => {
		if (serialPortStatus === SerialPortStatus.Connected) {
			disconnect();
		}

		connect();
	};

	const onClickNext = () => {
		if (lastMessage && lastMessage.senderId) {
			goToNextStep(lastRawModuleMessage?.deviceId || "", lastRawModuleMessage);
		}
	};

	const getConnectButtonText = () => {
		if (lastMessage && lastMessage.senderId) {
			return "Continue";
		}

		if (serialPortStatus === SerialPortStatus.Connected) {
			return "Stop searching...";
		}

		return "Connect";
	};

	const waitingForDataView = () => {
		if (serialPortStatus === SerialPortStatus.Connected) {
			return (
				<>
					<h2>Waiting for data</h2>
					<Portal name="actionbar">
						<button
							className="primary"
							type="button"
							onClick={onClickNext}
						>
							{getConnectButtonText()}
						</button>
					</Portal>
				</>
			);
		}

		return (
			<>
				<h2>Get started by connecting a device</h2>
				<Portal name="actionbar">
					<button
						className="primary"
						type="button"
						onClick={onClickConnect}
					>
						{getConnectButtonText()}
					</button>
				</Portal>
			</>
		);
	};

	const dataReceivedView = () => (
		<>
			<h2>Data Received</h2>
			<div className="infoSection">
				<p className="lastUpdate">{`Last received on server: ${dayjs(lastRawModuleMessage?.receivedAt).format("DD/MM/YYYY HH:mm:ss")}`}</p>
				<p className="lastUpdate">{`Device created at: ${dayjs(lastRawModuleMessage?.createdAt).format("DD/MM/YYYY HH:mm:ss")}`}</p>

				<div className="deviceData">
					<div>
						<h3>Device ID</h3>
						<p>{lastRawModuleMessage?.deviceId || "Waiting For Device ID"}</p>
					</div>
					<div>
						<h3>Payload</h3>
						<p>{JSON.stringify(lastRawModuleMessage?.data) || "Waiting For Device"}</p>
					</div>
				</div>
			</div>
			<Portal name="actionbar">
				<button
					className="primary"
					type="button"
					onClick={onClickNext}
				>
					{getConnectButtonText()}
				</button>
			</Portal>
		</>
	);

	return (
		<SerialDeviceSelectionScreenStyle>
			{
				!lastMessage ? waitingForDataView() : dataReceivedView()
			}
		</SerialDeviceSelectionScreenStyle>
	);
};
