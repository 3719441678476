import LoginPage from "pages/login/LoginPage";
import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/homePage/HomePage";

/**
 * This is the router of the application.
 * It handles everything that is based on the "Router state" (URL).
 * This enforces consistency on how URLs are handled throughout the application.
 */

const Router: FunctionComponent = () => (
	<Routes>
		<Route path="/" element={<HomePage />} />
		<Route path="/login" element={<LoginPage />} />
	</Routes>
);

export default Router;
