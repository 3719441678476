import { UIComponent } from "@aptus/frontend-core";
import React, { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
	name: string;
}

export const Portal: UIComponent<PropsWithChildren<PortalProps>> = ({ name, children }) => {
	const item = React.useMemo(() => document.querySelector(`[data-portal="${name}"]`), undefined);

	if (item) {
		return createPortal(children, item);
	}

	return null;
};
