interface Input {
	postLimiter?: string;
	preLimiter?: string;
}

class MessageBuffer {
	private readonly postLimiter: string;

	private readonly preLimiter: string;

	private buffer: string;

	constructor(input: Input) {
		const { preLimiter, postLimiter } = input;

		if (postLimiter && preLimiter) {
			throw new Error("Only one of postLimiter and preLimiter can be set");
		}

		this.postLimiter = postLimiter as string;
		this.preLimiter = preLimiter as string;

		this.buffer = "";
	}

	public hasFullMessages() {
		if (this.buffer.length === 0) {
			return false;
		}

		if (this.postLimiter && this.buffer.indexOf(this.postLimiter) !== -1) {
			return true;
		}

		if (this.preLimiter && this.buffer.indexOf(this.preLimiter) !== -1) {
			return true;
		}

		return false;
	}

	public push(data: string) {
		this.buffer += data;
	}

	public getFirstMessage() {
		if (this.preLimiter) {
			const firstPreLimiterIndex = this.buffer.indexOf(this.preLimiter);

			if (firstPreLimiterIndex === -1) {
				return null;
			}

			const secondPreLimiterIndex = this.buffer.indexOf(this.preLimiter, firstPreLimiterIndex + this.preLimiter.length + 1);

			const messageStart = firstPreLimiterIndex + this.preLimiter.length;
			const messageEnd = secondPreLimiterIndex > 0 ? secondPreLimiterIndex : this.buffer.length;

			const message = this.buffer.substring(messageStart, messageEnd);
			this.buffer = this.buffer.substring(messageEnd, this.buffer.length);

			return message;
		}

		if (this.postLimiter) {
			const postLimiterIndex = this.buffer.indexOf(this.postLimiter);

			if (postLimiterIndex !== -1) {
				const message = this.buffer.slice(0, postLimiterIndex);
				this.buffer = this.buffer.replace(message + this.postLimiter, "");

				return message;
			}
		}

		return null;
	}

	public clear() {
		this.buffer = "";
	}
}

export default MessageBuffer;
