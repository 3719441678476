import styled from "styled-components";

const SuccessScreenStyle = styled.div`
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
	height: calc(100vh - 130px);

	.summary {
		width: 100%;
		margin-top: 25px;

		p {
			padding: 5px;
		}

		table, td {
			border: 1px solid;
		}

		table {
			width: 100%;
			border-collapse: collapse;
		}
	}
`;

export default SuccessScreenStyle;
