import styled from "styled-components";

export const NFCDeviceSelectionScreenStyle = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.waitingForDataView {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.lastUpdate {
		opacity: 0.5;
		margin: 10px 0;
	}

	.deviceData {
		display: flex;
    	flex-direction: column;
	}

	.deviceData div {
		margin-bottom: 10px;
	}

	.infoSection {
		margin-bottom: 20px;
	}

	.good {
		color: green;
	}

	.bad {
		color: red;
	}
`;
