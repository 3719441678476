import Colors from "style/colors";
import styled from "styled-components";

export const ProjectScreenStyle = styled.div`
	margin-top: 20px;

	.project {
		margin: 0px;

		padding: 10px;
			&:nth-child(odd) {
				background-color: ${Colors.grey};
			}
	}
`;
