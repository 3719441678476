import { UILogic } from "@aptus/frontend-core";
import { useEffect, useState } from "react";

export interface Location {
	lat: number;
	lng: number;
	timestamp: number;
	accuracy: number;
}

interface Result {
	location?: Location;
}

export const useGeolocation: UILogic<{}, Result> = () => {
	const [location, setLocation] = useState<{ lat: number, lng: number, timestamp: number, accuracy: number }>();
	const [watcherId, setWatcherId] = useState<number | null>();

	useEffect(() => {
		if (!watcherId) {
			const id = navigator.geolocation.watchPosition((position: GeolocationPosition) => {
				const { coords, timestamp } = position;

				setLocation({
					lat: coords.latitude,
					lng: coords.longitude,
					timestamp,
					accuracy: coords.accuracy,
				});
			}, () => { }, {
				enableHighAccuracy: true,
				maximumAge: 0,
			});

			setWatcherId(id);
		}

		return () => {
			if (watcherId) {
				navigator.geolocation.clearWatch(watcherId);
				setWatcherId(null);
			}
		};
	}, []);

	return {
		location,
	};
};
