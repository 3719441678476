import { UseCases } from "@aptus/frontend-core";
import { InternalAPI } from "models/internalAPI";
import { Project } from "./models/project";

export type ProjectsAPI = InternalAPI<Project[]>;

interface Props {
	API: ProjectsAPI;
}

interface Result {
	projects: Project[];
	isLoading: boolean;
	error?: Error;
}

export const useProjectsUseCases: UseCases<Props, Result> = ({ API }) => (
	{
		error: API.error,
		isLoading: API.isLoading,
		projects: API.data,
	}
);
