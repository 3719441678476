/* eslint-disable */
import * as Types from '../../../../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetSchoolyearsVariablesDTO = Types.Exact<{
  projectId?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetSchoolyearsDTO = (
  { __typename?: 'Query' }
  & { schoolyears: Array<(
    { __typename?: 'Schoolyear' }
    & Pick<Types.SchoolyearDTO, 'id' | 'title'>
  )> }
);


export const GetSchoolyearsDocumentDTO = gql`
    query getSchoolyears($projectId: String) {
  schoolyears(filter: {project: {equals: $projectId}}) {
    id
    title
  }
}
    `;

/**
 * __useGetSchoolyears__
 *
 * To run a query within a React component, call `useGetSchoolyears` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolyears` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolyears({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSchoolyears(baseOptions?: Apollo.QueryHookOptions<GetSchoolyearsDTO, GetSchoolyearsVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSchoolyearsDTO, GetSchoolyearsVariablesDTO>(GetSchoolyearsDocumentDTO, options);
      }
export function useGetSchoolyearsLazyQueryDTO(baseOptions?: Apollo.LazyQueryHookOptions<GetSchoolyearsDTO, GetSchoolyearsVariablesDTO>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSchoolyearsDTO, GetSchoolyearsVariablesDTO>(GetSchoolyearsDocumentDTO, options);
        }
export type GetSchoolyearsHookResult = ReturnType<typeof useGetSchoolyears>;
export type GetSchoolyearsLazyQueryDTOHookResult = ReturnType<typeof useGetSchoolyearsLazyQueryDTO>;
export type GetSchoolyearsQueryResultDTO = Apollo.QueryResult<GetSchoolyearsDTO, GetSchoolyearsVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    