import Colors from "style/colors";
import styled from "styled-components";

export const ModuleScreenStyle = styled.div`
	.moduleList {
		margin-top: 20px;
		margin-bottom: 60px;

		.moduleRecord {
			padding: 10px;
			&:nth-child(odd) {
				background-color: ${Colors.grey};
			}

			&.selected {
				background-color: ${Colors.green} !important;
				color: ${Colors.grey};
			}

			&.jobstudent {
				background-color: ${Colors.highFiveBlue};
				color: ${Colors.grey};
			}

			&.robin {
				animation: rainbow-bg 2.5s linear;
				animation-iteration-count: infinite;
			}

			.deviceId {
				font-size: 10px;
			}
		}
	}

	.good {
		color: green;
	}

	.medium {
		color: orange;
	}

	.bad {
		color: red;
	}

	@keyframes rainbow-bg {
		100%,0%{
			background-color: rgb(255,0,0);
		}
		8%{
			background-color: rgb(255,127,0);
		}
		16%{
			background-color: rgb(255,255,0);
		}
		25%{
			background-color: rgb(127,255,0);
		}
		33%{
			background-color: rgb(0,255,0);
		}
		41%{
			background-color: rgb(0,255,127);
		}
		50%{
			background-color: rgb(0,255,255);
		}
		58%{
			background-color: rgb(0,127,255);
		}
		66%{
			background-color: rgb(0,0,255);
		}
		75%{
			background-color: rgb(127,0,255);
		}
		83%{
			background-color: rgb(255,0,255);
		}
		91%{
			background-color: rgb(255,0,127);
		}
	}
`;
