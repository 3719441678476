import { UILayout } from "@aptus/frontend-core";
import { Portal } from "components/portal/Portal";
import dayjs from "dayjs";
import { RawModuleMessage } from "hooks/rawModuleMessage/models/rawModuleMessage";
import { useState } from "react";
import { ManualDeviceSelectionScreenStyle } from "./ManualDeviceSelectionScreenStyle";

interface Props {
	goToNextStep: (deviceId: string, nfcLastRawModuleMessage: RawModuleMessage | undefined) => void;
}

export const ManualDeviceSelectionScreen: UILayout<Props> = ({
	goToNextStep,
}) => {
	const [lastRawModuleMessage, setLastRawModuleMessage] = useState<RawModuleMessage | undefined>();

	const onClickNext = () => {
		if (lastRawModuleMessage && lastRawModuleMessage.deviceId) {
			goToNextStep(lastRawModuleMessage.deviceId || "", lastRawModuleMessage);
		}
	};

	const setManualDeviceId = (deviceId: string) => {
		setLastRawModuleMessage({
			id: "MANUAL",
			createdAt: new Date(),
			receivedAt: new Date(),
			deviceId,
			data: {},
		});
	};

	const waitingForDataView = () => (
		<>
			<h2>Waiting For Data</h2>
		</>
	);

	const dataReceivedView = () => (
		<>
			<h2>Data Received</h2>
			<div className="infoSection">
				<p className="lastUpdate">{`Last received on server: ${dayjs(lastRawModuleMessage?.receivedAt).format("DD/MM/YYYY HH:mm:ss")}`}</p>
				<p className="lastUpdate">{`Device created at: ${dayjs(lastRawModuleMessage?.createdAt).format("DD/MM/YYYY HH:mm:ss")}`}</p>

				<div className="deviceData">
					<div>
						<h3>Device ID</h3>
						<p>{lastRawModuleMessage?.deviceId || "Waiting For Device ID"}</p>
					</div>
					<div>
						<h3>Payload</h3>
						<p>{JSON.stringify(lastRawModuleMessage?.data) || "Waiting For Device"}</p>
					</div>
				</div>
			</div>

		</>
	);

	return (
		<ManualDeviceSelectionScreenStyle>
			{
				!lastRawModuleMessage ? waitingForDataView() : dataReceivedView()
			}
			<Portal name="actionbar">
				<input type="text" className="textInput" placeholder="Device ID" onChange={(e) => setManualDeviceId(e.currentTarget.value)} />
				<button
					className="primary"
					type="button"
					onClick={onClickNext}
				>
					Next
				</button>
			</Portal>
		</ManualDeviceSelectionScreenStyle>
	);
};
