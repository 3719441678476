import { UseCases } from "@aptus/frontend-core";
import { InternalAPI } from "models/internalAPI";
import { Schoolyear } from "./models/schoolyear";

export type SchoolyearsAPI = InternalAPI<Schoolyear[]>;

interface Props {
	API: SchoolyearsAPI;
}

interface Result {
	schoolyears: Schoolyear[];
	isLoading: boolean;
	error?: Error;
}

export const useSchoolyearsUseCases: UseCases<Props, Result> = ({ API }) => (
	{
		error: API.error,
		isLoading: API.isLoading,
		schoolyears: API.data,
	}
);
