import { UILayout } from "@aptus/frontend-core";
import stickerModule from "assets/sticker-module.png";
import { Portal } from "components/portal/Portal";
import { useModules } from "domains";
import { Location } from "hooks/geolocation/useGeolocation";
import { useState } from "react";
import { useGetModules } from "../moduleScreen/models/getModules";
import { ReferenceScreenStyle } from "./ReferenceScreenStyle";

interface Props {
	moduleId: string;
	deviceId: string;
	location?: Location;
	currentReference?: string;
	goToNextStep: () => void;
}

export const ReferenceScreen: UILayout<Props> = ({
	moduleId,
	deviceId,
	location,
	currentReference,
	goToNextStep,
}) => {
	const [reference, setReference] = useState<string | undefined>(currentReference || "BE-2023-");

	const { update } = useModules(useGetModules({ variables: { filter: { id: { equals: moduleId } } } }));

	const onClickNext = async () => {
		if (!reference || !location?.accuracy) {
			return;
		}

		await update({
			id: moduleId,
			deviceId: deviceId || "",
			lat: location?.lat,
			lng: location?.lng,
			reference,
		});

		goToNextStep();
	};

	return (
		<ReferenceScreenStyle>
			<h1>Reference</h1>
			<p>Fill in the reference on the modules sticker</p>
			<input className="textInput" type="text" onChange={(e) => setReference(e.currentTarget.value)} defaultValue={currentReference} />
			<img src={stickerModule} alt="Sticker Module Example" />

			<Portal name="actionbar">
				<button type="button" className="primary" disabled={!location || !reference} onClick={onClickNext}>Next</button>
			</Portal>
		</ReferenceScreenStyle>
	);
};
