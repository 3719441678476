import { PageComponent } from "@aptus/frontend-core";
import Header from "components/header/header";
import { Private } from "components/private";
import { Location } from "hooks/geolocation/useGeolocation";
import { RawModuleMessage } from "hooks/rawModuleMessage/models/rawModuleMessage";
import isJwtTokenExpired from "jwt-check-expiry";
import { useState } from "react";
import HomePageStyle from "./HomePageStyle";
import { DeviceScreen } from "./components/screens/deviceScreen/DeviceScreen";
import { ManualDeviceSelectionScreen } from "./components/screens/manualDeviceSelectionScreen/ManualDeviceSelectionScreen";
import { ModuleScreen } from "./components/screens/moduleScreen/ModuleScreen";
import { NFCDeviceSelectionScreen } from "./components/screens/nfcDeviceSelectionScreen/NFCDeviceSelectionScreen";
import { NFCSelectionScreen } from "./components/screens/nfcSelectionScreen/NFCSelectionScreen";
import { ProjectScreen } from "./components/screens/projectScreen/ProjectScreen";
import { ReferenceScreen } from "./components/screens/referenceScreen/ReferenceScreen";
import { SchoolyearScreen } from "./components/screens/schoolyearScreen/schoolyearScreen";
import { SerialDeviceSelectionScreen } from "./components/screens/serialDeviceSelectionScreen/SerialDeviceSelectionScreen";
import { StartScreen } from "./components/screens/startScreen/StartScreen";
import { SuccessScreen } from "./components/screens/successScreen/SuccessScreen";

enum Screen {
	Start,
	NFCSelection,
	NFCDeviceSelection,
	SerialDeviceSelection,
	ManualDeviceSelection,
	Device,
	Module,
	Success,
	Project,
	Schoolyear,
	Reference,
}

const HomePage: PageComponent = () => {
	const [currentScreen, setCurrentScreen] = useState(Screen.Start);
	const [tagId, setTagId] = useState<string | undefined>();
	const [projectId, setProjectId] = useState<string | undefined>();
	const [schoolyearId, setSchoolyearId] = useState<string | undefined>();
	const [deviceId, setDeviceId] = useState<string | undefined>();
	const [moduleId, setModuleId] = useState<string | undefined>();
	const [reference, setReference] = useState<string | undefined>();
	const [lastRawModuleMessage, setLastRawModuleDeviceMessage] = useState<RawModuleMessage | undefined>();
	const [location, setLastLocation] = useState<Location | undefined>();

	const getStartScreen = () => (
		<StartScreen
			goToNFCStep={() => {
				setCurrentScreen(Screen.NFCSelection);
			}}
			goToSerialStep={() => {
				setCurrentScreen(Screen.SerialDeviceSelection);
			}}
			goToManualStep={() => {
				setCurrentScreen(Screen.ManualDeviceSelection);
			}}
		/>
	);

	const logout = () => {
		localStorage.setItem("token", "");
		window.location.replace(localStorage.getItem("redirectRoute") || "/");
	};

	const renderCurrentScreen = () => {
		switch (currentScreen) {
			case Screen.Start:
				return getStartScreen();
			case Screen.NFCSelection:
				return (
					<NFCSelectionScreen
						goToNextStep={(selectedTagId) => {
							setTagId(selectedTagId);
							setCurrentScreen(Screen.NFCDeviceSelection);
						}}
					/>
				);
			case Screen.NFCDeviceSelection:
				return (
					<NFCDeviceSelectionScreen
						tagId={tagId || ""}
						goToNextStep={(selectedDeviceId, nfcLastRawModuleMessage) => {
							setDeviceId(selectedDeviceId);
							setLastRawModuleDeviceMessage(nfcLastRawModuleMessage);
							setCurrentScreen(Screen.Device);
						}}
					/>
				);
			case Screen.SerialDeviceSelection:
				return (
					<SerialDeviceSelectionScreen
						goToNextStep={(selectedDeviceId, nfcLastRawModuleMessage) => {
							setDeviceId(selectedDeviceId);
							setLastRawModuleDeviceMessage(nfcLastRawModuleMessage);
							setCurrentScreen(Screen.Device);
						}}
					/>
				);
			case Screen.ManualDeviceSelection:
				return (
					<ManualDeviceSelectionScreen
						goToNextStep={(selectedDeviceId, nfcLastRawModuleMessage) => {
							setDeviceId(selectedDeviceId);
							setLastRawModuleDeviceMessage(nfcLastRawModuleMessage);
							setCurrentScreen(Screen.Device);
						}}
					/>
				);
			case Screen.Project:
				return (
					<ProjectScreen
						goToNextStep={(selectedProjectId: string) => {
							setProjectId(selectedProjectId);
							setCurrentScreen(Screen.Schoolyear);
						}}
					/>
				);
			case Screen.Schoolyear:
				return (
					<SchoolyearScreen
						projectId={projectId || ""}
						goToNextStep={(selectedSchoolyearId: string) => {
							setSchoolyearId(selectedSchoolyearId);
							setCurrentScreen(Screen.Module);
						}}
					/>
				);
			case Screen.Device:
				return (
					<DeviceScreen
						deviceId={deviceId || ""}
						lastRawModuleMessage={lastRawModuleMessage}
						goToNextStep={(selectedDeviceId: string) => {
							setDeviceId(selectedDeviceId);
							setCurrentScreen(Screen.Project);
						}}
					/>
				);
			case Screen.Module:
				return (
					<ModuleScreen
						deviceId={deviceId || ""}
						schoolyearId={schoolyearId || ""}
						goToNextStep={(selectedModuleId: string, selectedlocation: Location, currentReference?: string) => {
							setModuleId(selectedModuleId);
							setLastLocation(selectedlocation);
							setReference(currentReference);
							setCurrentScreen(Screen.Reference);
						}}
					/>
				);
			case Screen.Reference:
				return (
					<ReferenceScreen
						moduleId={moduleId || ""}
						deviceId={deviceId || ""}
						location={location}
						currentReference={reference}
						goToNextStep={() => setCurrentScreen(Screen.Success)}
					/>
				);
			case Screen.Success:
				return (
					<SuccessScreen
						moduleId={moduleId}
						location={location}
						startOver={() => setCurrentScreen(Screen.Start)}
					/>
				);
			default:
				return getStartScreen();
		}
	};

	return (
		<Private isAuthorized={!!(localStorage.getItem("token") && !isJwtTokenExpired(localStorage.getItem("token") || ""))} redirectTo="/login">
			<HomePageStyle>
				<Header goHome={() => setCurrentScreen(Screen.Start)} logout={logout} />
				<div style={{ height: "100%", padding: "10px" }}>
					{renderCurrentScreen()}
				</div>
			</HomePageStyle>
		</Private>
	);
};

export default HomePage;
