/* eslint-disable */
import * as Types from '../../../models/schema.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LoginVariablesDTO = Types.Exact<{
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type LoginDTO = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResult' }
    & Pick<Types.LoginResultDTO, 'token'>
  ) }
);


export const LoginDocumentDTO = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
  }
}
    `;
export type LoginMutationFnDTO = Apollo.MutationFunction<LoginDTO, LoginVariablesDTO>;

/**
 * __useLogin__
 *
 * To run a mutation, you first call `useLogin` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogin` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [login, { data, loading, error }] = useLogin({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogin(baseOptions?: Apollo.MutationHookOptions<LoginDTO, LoginVariablesDTO>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginDTO, LoginVariablesDTO>(LoginDocumentDTO, options);
      }
export type LoginHookResult = ReturnType<typeof useLogin>;
export type LoginMutationResultDTO = Apollo.MutationResult<LoginDTO>;
export type LoginMutationOptionsDTO = Apollo.BaseMutationOptions<LoginDTO, LoginVariablesDTO>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SearchItem": [
      "ChallengeSearchItem",
      "ClassSearchItem",
      "ExerciseSearchItem",
      "PupilSearchItem",
      "RewardSearchItem",
      "SchoolSearchItem"
    ]
  }
};
      export default result;
    