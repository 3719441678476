import styled from "styled-components";

export const ManualDeviceSelectionScreenStyle = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.lastUpdate {
		opacity: 0.5;
		margin: 10px 0;
	}

	.deviceData {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 1fr 1fr;
	}

	.infoSection {
		margin-bottom: 20px;
	}
`;
