import { UILayout } from "@aptus/frontend-core";
import { Portal } from "components/portal/Portal";
import { useState } from "react";
import StartScreenStyle from "./StartScreenStyle";

interface Props {
	goToNFCStep: () => void;
	goToSerialStep: () => void;
	goToManualStep: () => void;
}

export const StartScreen: UILayout<Props> = ({
	goToNFCStep,
	goToSerialStep,
	goToManualStep,
}) => {
	const [showManualProvision, setShowManualProvision] = useState<boolean>(false);

	const onClickNFC = () => {
		goToNFCStep();
	};

	const onClickSerial = () => {
		goToSerialStep();
	};

	return (
		<StartScreenStyle>
			<h2 onClick={() => (setShowManualProvision(!showManualProvision))} aria-hidden="true">
				How do you want to provision?
			</h2>
			<Portal name="actionbar">
				{
					showManualProvision
					&& (
						<button
							type="button"
							onClick={goToManualStep}
							className={!showManualProvision ? "hidden primary" : "primary"}
						>
							Manual
						</button>
					)
				}
				<button
					className="primary wired-provision"
					type="button"
					onClick={onClickSerial}
				>
					Wired
				</button>
				<button
					className="primary wireless-provision"
					type="button"
					onClick={onClickNFC}
				>
					Wireless
				</button>
			</Portal>
		</StartScreenStyle>
	);
};
