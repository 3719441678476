// This is a strictly typed version of the default NodeJS EventEmitter.
import { StrictEventEmitter } from "strict-event-emitter";

type Events = {
	mutationFailed: (error: Error) => void;
	tokenExpired: () => void;
};

/**
 * Allows to emit events and listen to them. This allows us to chain different Interaction Domains
 * together without explicitly depending on each other.
 */
export const event = new StrictEventEmitter<Events>();
