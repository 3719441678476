import Page from "pages/base/Page";
import Colors from "style/colors";
import styled from "styled-components";

export default styled(Page)`
	background: linear-gradient(180deg, #ebf1f4, #ffffff);

	h3 {
		font-size: 1.6em;
		margin-top: 24px;
		margin-bottom: 16px;
	}

	.metrics-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 24px;
	}

	table {
		font-size: 0.8em;
		border-collapse: collapse;

		th {
			background-color: ${Colors.grey};
		}

		th,
		td {
			border: 1px solid rgba(0,0,0,0.1);
			margin: 0;
			padding: 4px;
		}
	}
`;
