import { UILayout } from "@aptus/frontend-core";
import { useSchoolyears } from "domains";
import { useGetSchoolyears } from "./models/getSchoolyears";
import { SchoolyearScreenStyle } from "./schoolyearScreenStyle";

interface Props {
	projectId: string;
	goToNextStep: (schoolyearId: string) => void;
}

export const SchoolyearScreen: UILayout<Props> = ({
	projectId,
	goToNextStep,
}) => {
	const { schoolyears } = useSchoolyears(useGetSchoolyears({ variables: { projectId } }));

	const onClickSchoolyear = (schoolyearId: string) => {
		goToNextStep(schoolyearId);
	};

	return (
		<SchoolyearScreenStyle>
			<h1>SCHOOLYEARS</h1>
			{schoolyears.map((schoolyear, index) => (
				<div className="schoolyear" onClick={() => onClickSchoolyear(schoolyear.id)} key={schoolyear.title} role="button" tabIndex={index} aria-hidden="true">
					<h2>{schoolyear.title}</h2>
				</div>
			))}
		</SchoolyearScreenStyle>
	);
};
